.leave_list .popup .inner{
    top:50%;
    width:80%;
}

.leave_list .popup .inner table th {
    background-color: transparent;
}
.leave_list select{
    padding:5px;
    margin-right:5px;
    border:1px solid #ddd;
    margin-bottom:10px;
}

.leave_list table{
    border-collapse: collapse;
    width:100%;
    margin-top:20px;
    text-align: center;
    
}

.leave_list table thead tr{
    
    background: rgb(245, 252, 255);
    border-top: 1px solid rgb(157, 207, 252);
    border-bottom: 1px solid rgb(157, 207, 252);
}

.leave_list table thead tr th{
    padding: 5px 0px;
}

.leave_list table tr th:first-of-type{
    width:10%

}

.leave_list table tr th:nth-of-type(2){
    width:5%

}

.leave_list table tr th:nth-of-type(3){
    width:12%

}

.leave_list table tr th:nth-of-type(4){
    width:5%

}

.leave_list table tr th:nth-of-type(5){
    width:5%

}
.leave_list table tr th:nth-of-type(6){
    width:8%

}
.leave_list table tr td{
    border:1px solid #ddd;
    padding:7px;
}
.leave_list table tr td:first-of-type{
    border-left:none;

}
.leave_list table tr td:last-of-type{
    border-right:none;

}
.leave_list table tr td:nth-of-type(7){
    text-align: left;

}
.leave_list .leave_view td{
    margin:0;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    height:auto;
    line-height:20px;

}
.leave_list .popup .inner {
    height:70%;
    position:relative;
    overflow-y:scroll;
    -webkit-overflow-scrolling: touch; 

      left:50%;
      transform:translateX(-50%) translateY(-50%);
}
.leave_table:not(:first-of-type){
    border-top:1px solid #000;
    padding-top:10px;
}
@media (max-width: 1300px) {
    .leave_list table tr{
        margin-bottom:20px;
    }
.leave_list table tr td {
    padding:0;
    padding-right: 10px;
    /* padding: 7px; */
    border-left: none;
    border-right: none;
    line-height:25px;
}

.responsive td{
    display:flex !important;
    align-items: center;
}


.leave_history th{
    display:none;
}
.leave_history tr{
    height:auto;
}
.leave_history tr:nth-of-type(1){
    display:none;
}

.leave_history td button{
    left:0;
    transform: none;
    
}
.responsive td::before {
    content: attr(data-label);
    float: left;
    height: 22px;
    padding-right:10px;
    line-height:20px;
    font-weight: bold;
    width: 100px;
    background: rgb(245, 252, 255);
    text-transform: uppercase;
}
.leave_list table tr td:nth-of-type(7){
    text-align: center;

}

.leave_list .popup{
    position: absolute;
    
}
.leave_list .popup .inner{
    top:720px;
    width:100%;

}
.leave_list .popup .inner .all_search, .board_wrapper {
    width: 100%;
   
}




}

@media (max-width: 600px) {
    .leave_list .popup .inner {
        height:100%;
        position:absolute;
          left:0;
          top:0;
          padding:20px 0;
    }
    .leave_list .popup {
        display: none;
    }
}

.ot_view table{
    width:100%;
    table-layout: fixed;
    margin-bottom:10px;
}

.ot_view td {
    border:1px solid #666;
    height:30px;
    line-height: 30px;
    padding:0 10px;

}
.ot_view .table_title2{
    width:auto;
    background-color: #eee;
}

