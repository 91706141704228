.form_table thead th{
    background-color: #f7f7f7;
    padding:10px 0px;
}
.form_table {
    width:60%;
    margin-top:20px;
}
.form_table{
    border-collapse: collapse;
}

.form_title{
    display:flex;
    align-items: center;
}


.form_table td{
    height:30px;
}

.form_table tr{
    border-bottom:1px solid #ddd;
}
.form_table li{
    padding:3px 0;
    display:flex;
    align-items: center;
}
.form_table li:hover{
color:rgb(0, 145, 255);
}
.form_table li span{
    color:#999999;
      
}
.form_table .title{
    text-align: center;
    padding:10px 0;
}
.form_table td:nth-of-type(2){
    padding-left:70px;
}
.form_table svg{
    margin-right:5px;
    color:#8aa3ee;
}

.form_table span svg{
    color:#fbd53b;
    cursor:pointer;
    font-size:18px;
    display:flex;
    align-items: center;
}
.form_table .notbookmark svg{
    color:#ddd;
    cursor:pointer;
}

.form_write{
    border:1px solid #ddd;
}
.form_write tr{
    display:flex;
}
.form_write tr {
    min-height:45px;
    border-bottom:1px solid #ddd;
}
.form_write .input-group, .form_write .input-group input {
    height:100%;
}
.form_write .table_list2_left{
    display:flex;
    border:none;
    justify-content: center;
}

.form_write .table_title2{
    width:160px;
    background-color: #f7f7f7;
    padding-left:10px;
}

.form_write .radio{
    display:flex;
    height:15px;
}
.form_write .radio input{
    margin:0;
    width:20px;
    height:15px;
}
.form_write .radio label{
    
    margin-right:15px;
    display:flex;align-items: center;
}

.form_write .table_list2_left{
    display: flex;
    flex-direction: column;
    position: relative;
}
.form_write .admin_list{
    border:1px solid #ddd;
    margin-top:10px;
    margin-right:auto;
    padding:0 10px;
}
.form_write .admin{
    display:flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    padding:5px 10px;
}
.del_btn{
    cursor: pointer;
    display: flex;
    align-items: center;
    
}

.form_write .input-group{
    display:flex;
    align-items: center;
}
.form_write select{
    border:1px solid #666;
    padding:5px;
    margin-right:5px;
}
.form_write .fortune-toolbar{
    flex-wrap: wrap;
}

.form_write .fortune-workarea{
    width:100% !important;
    
}
@media print
{    
    .luckysheet-cell-selected, .luckysheet-cell-selected-focus{
        display:none !important;
        visibility: hidden;
    }
}
.docview .luckysheet-cell-selected, .docview .luckysheet-cell-selected-focus{
    display:none !important;
}
.fortune-toolbar-divider:nth-of-type(6){
    flex-basis: 100%;
    height: 0;
}
.line_table{
    margin-top:20px;
}

.line_table table{
    border-collapse: collapse;
}
.line_table table thead th{
    padding:10px 0;
    text-align: center;
    background-color: #e3f2fd;
    font-weight:normal;
    color:#0a2d5e;
}
.line_table table td{
    text-align: center;
    padding:5px 0;
    border-bottom:1px solid #ddd;
}
.line_table table td:nth-of-type(1){
    width:3%;
}

.line_table table td:nth-of-type(2){
    width:15%;
}


.line_table table tr:hover{
    background-color: #fbfbfb;
}



.line_table .arrows{
    font-size:18px;
}
.line_table svg{
    cursor: pointer;
    color:#8aa3ee;
}
.line_table svg:hover{
    color:#0a2d5e;
}
.line_table .trash{
    color:#999999;
}

.line_main span{
    margin-right:10px;
}

.line_main input{
    width:200px;
    border:1px solid #ddd;
    padding:4px 5px;
}

.line_table input{
    width:200px;
}
.line{
    justify-content: end;
}
.line table{
    border-collapse: collapse;
    border:1px solid #ddd;
    margin-right:5px;
    min-width:110px;
}
.line table:last-of-type{
    margin-right:0;
}
.line table td{
    font-size:11px;
    border:1px solid #ddd;
    text-align: center;
    position:relative;
    padding:5px 10px;
}

.line table .type{
    padding:5px 10px;
    width:10px;
    background-color: #f7f7f7;
}

.line table .job{
    height:30px;
}

.line table .name{
    min-width:68.7px;
    padding:5px 0;
    border:none;
    height:20px;
}

.line table img{
    padding:10px 0;
}

.line table .approved_date{
    padding:5px 0;
    width:80px;
    height:35px;
}

.slash {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="100%" x2="100%" y2="0" stroke="gray" /></svg>');
  }

.form_content{
    height:600px;
}

.main_content .custom_form .btns{
    width:100%;
}

@media (max-width: 1300px) {
    .form_content{
        height:400px;
    }
    .line table td {
        padding:5px;
    }
    .line table .name {
        min-width: 50px;

    }
    .form_write .table_title2{

        width:80px;
    }
    .form_table {
        width:100%;
        table-layout: fixed;
    }
 
    .form_table .title, .form_table th:nth-of-type(1) {
        min-width:100px !important;
        text-align: left;
    }
    
    .form_table td:nth-of-type(2), .form_table th:nth-of-type(2) {
        padding:0px;
        width:80%;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .form_table li {
        max-width:100%;
    }
    .form_table li span {
        
        overflow: hidden;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}



@media (max-width: 690px) {

.form_write .fortune-toolbar {
    /* flex-wrap: wrap; */
    overflow-x: scroll;
    overflow-y: hidden;
    max-width: 280px;
    flex-wrap: nowrap;
}

.fortune-toolbar-more-container{
    position: fixed !important;
    top:60px !important;
}
.line{
    flex-direction: column;
    width:100%;
}
.line table .type{
    width:5%;
}


.line table .approved_date{
    width:95%;
}
.line table{
    width:100%;
}

}