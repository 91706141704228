.container{
    left:200px;
    top:50px;
    width: 100%;
  }
  .mail_view_header{
    margin:0 25px;
    padding:0px 10px 20px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(230, 230, 234, 0.7);
  }

.mail_toolbar_task {
  display:flex;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  min-width: 0;
  margin:10px 35px;
  padding: 8px 0;
}
.mail_view_wrapper p{
  margin-top:0 !important;
  margin-bottom:0 !important;
}
  .mail_title{
    padding:0 110px 0 24px;
    box-sizing: border-box;
    word-break:break-all;
    overflow-wrap: break-word;
  }
  .mail_date, .mail_from{
    padding: 0 0 0 24px;
  }
  .mail_view_body {
    padding: 0 0 5px 25px;
    box-sizing: border-box;
  }
  .mail_view_contents{
    padding: 20px 25px 0 34px;
    box-sizing: border-box;
  }
.mail_view_contents img{
  max-width:100%;
}
  .mail_toolbar {
    display:flex;
  }
 
  .mail_toolbar_task .button_group {
    display:flex;
  }
.add_addr, .block_addr{
  cursor: pointer;
  font-size:12px;
  background-color: rgb(227, 242, 255);
  padding:2px 15px;
  border-radius: 25px;
}
.add_addr{
  margin-left:15px;
}
.block_addr{
  margin: 0 15px;
  position: relative;
}
.mail_from span{
  cursor: pointer;
  
}
.mail_from span:hover{
  text-decoration: underline;
}
.mail_from{
  position: relative;
  margin-top:8px;
  display:flex;

}
.mail_from .addr{
  display:flex;
  width: 100%;
  flex-wrap: wrap;
}

.block_addr::before{
  position: absolute;
  top: 3px;
  left: -9px;
  width: 1px;
  height: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  content: '';
}

.bbsbotm_tbl{
  width:95%;
  display: flex;
  justify-content: space-between;
  margin:0 auto;
  
}
.tbl_basic, .tbl_basic table {
  width:100%;
  border-collapse: collapse;
}
.tbl_basic table tbody tr{
  width:100%;
  border: 1px solid #ddd;
  position: relative;
}
.tbl_basic table tbody tr td:first-of-type{
width:2%;
padding-left:10px;
min-width:30px;
}
.tbl_basic table tbody tr td:nth-of-type(2){
  width:8%;
  min-width:200px;

}
.bbsbotm_tbl .subject{
  width:79%;
}
.bbsbotm_tbl .hide-m{
  width:10%;
  color:gray;
  min-width:200px;
}
.bbsbotm_tbl a {
  text-decoration: none;
  display: inline-block;
  padding: 8px 16px;
}



.previous {
  background-color: #f1f1f1;
  color: black;
}

.next {
  background-color: #04AA6D;
  color: white;
}

.round {
  border-radius: 50%;
}

.attachment_box{
  width:100%;
  box-sizing: border-box;
  border-bottom:1px solid #ddd;
  padding:10px 15px;
}

.attachment_size{
  color:#b9b8b8;
}

.attachment svg{
  border:1px solid #4f4e4e;
  margin-right:10px;
  border-radius: 2px;
}
.attachment_box svg{
  border:none;
  font-size:20px;
}
.attachment_box div{
  display:flex;
  align-items: center;
  margin-bottom:5px;
}

/*********************  BOARD VIEW  ************************/

.board_header{
  display: flex;
  align-items: center;
}

.board_header .text_info{
  margin-left:10px;
  /*width:33%;*/
  font-weight: normal;
}
.board_header .text_info .date{
  color:#8b8b8b;
}

.mail_view_contents .fortune-workarea{
  display:none;
}

.mail_view_contents #luckysheet-sheet-area{
  display:none;
}



.mail_view_contents .fortune-col-header-wrap{
  background-color: white;
}

.mail_view_contents .fortune-row-header, .mail_view_contents .fortune-left-top{
  background-color: white;
  
}
.mail_view_contents #luckysheetrowHeader_0{
  display:none;
  background-color: white;
}
.mail_view_contents .fortune-col-header-selected,.mail_view_contents .fortune-row-header-selected{
  display:none !important
}
.mail_view_contents .fortune-workarea .fortune-toolbar{
  flex-wrap: wrap;
}
.mail_view_contents .fortune-toolbar-divider{
  flex-basis: 100%;
  height: 0;
}
.mail_view_contents .luckysheet-input-box{
  display:none;
}
.fortune-row-header{
  color:white;
  position: absolute;
}
.mail_view_contents .luckysheet-bottom-controll-row{
  display:none !important;
}

.mail_view_contents .luckysheet-input-box{
  display:none !important;
}

.mail_view_contents .fortune-context-menu{
  display:none !important;
}
.mail_view_contents .luckysheet-sheet-selection-calInfo{
  display:none;
}


.dropdown{
  position: absolute;
  width: auto;
  left:100px;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background-color: white;
  z-index: 9999;
}
.addr_card .list{
  width:100%;
  margin-top:20px;
  display:flex;
  color:rgb(104, 104, 104);
  justify-content: space-between;
  cursor:pointer;
}
.addr_card .list .item{
  display: flex; 
  align-items: center;
  flex-direction: column;
  width:65px;
  margin-right:12px;
  text-align: center;
}

.addr_card .list .item svg{
  font-size:17px;
  margin-bottom: 7px;
}

.addr_card .list button{
  width:fit-content;
  background-color: white;
  border:none;
  padding:0;
  margin:0;
  color:rgb(104, 104, 104);
  font-size:12px;
}

.board_wrap .board_top .btns{
  display: flex;
  justify-content: start;
 
  width: 20%;
  margin: 0px 0px 20px;
}
.mail_view_contents{
  width:95%;
  height:auto;
}

.attachment_box button{
  background-color: white;
  border:1px solid #ddd;
  padding:5px 10px;

  cursor: pointer;
}

.button_group.mobile button{
  padding:8px 10px;
  border:none;
}
.button_group.mobile button span svg{
  font-size: 17px;
}

.mail_toolbar_task .mobile {
  display: none;
}
@media (max-width: 1300px) {
  .board_wrap .board_top .btns{
    width:100%;
    margin:0;
  }
  .container{
    width:100%;
  }
  .mail_title{
    padding:0;
    margin:0;
  }
  .mail_view_header{
    margin:0;
  }

.add_addr, .block_addr{
  display:none;
}

.mail_view_body{
  width:100%;
}
.mail_view_wrap{
  width:100%;
}

.mail_toolbar_task {

padding:0px 10px 20px 10px;
}


.board_container .board_top .btns{
  display:none;
}
.bbsbotm_tbl .hide-m {
min-width:unset;
}
.mail_view_contents{
  width:100%;
  padding:5px;
  height:auto;
}
.mail_view_body{
  padding:5px;
}
.attachment_box button{
font-size:10px;
width:110px;
}

.tbl_basic table tbody tr td:nth-of-type(2) {
width:10%;
min-width:unset;

}

.bbsbotm_tbl .subject {

width:60%;
}
.mail_toolbar_task{
  width:100%;
  margin:0;
  padding:0;
  border-bottom: 1px solid #ddd;
}
.mail_toolbar_task .mobile {
  display: flex;
}

.bbsbotm_tbl .hide-m{
  width:15%;
}
.mail_from, .mail_date{
  padding:0;
}
.dropdown{
  left:0;
}
.dropdown_div.mobile{
  top:100px;
  left:0;right:0;
  margin:auto;
}
}

.comment{
  padding:25px;
  margin-right:25px;
}
.view_table{
  margin:20px 35px;
}

@media (max-width: 1300px) {

  .comment{
      padding:0;
      margin-right:0;
  }

  .view_table{
      margin:20px 0;
  }
  .bbsbotm_tbl{
      width:100%;
  }

}