.overlay__background {
    background-color: rgba(0, 0, 0, 0.5);
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    cursor: pointer;
    z-index: 9;
  }
  
  .overlay__container {
    background-color: white;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    z-index: 100000000;
    width: 100%;
    height: 100vh;
  }
  
  .overlay__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .overlay__close:after {
    display: inline-block;
    content: "\00d7";
  }
  
  .overlay__close {
    border: none;
    position:absolute;
    top:6px;
    right:6px;
    background-color: rgb(3, 12, 27);
    width:70px;
    font-size: 36px;
    color:white;
    cursor: pointer;
  }