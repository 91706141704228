.outer_wrapper h2{
    margin:10px 0;
}
.board_nav{
    border-right:1px solid #ddd;
    background-color: #f7f7f7;
    padding:0px;
    /*height:calc(100vh - 60px);*/
    height:100%;
    width:260px;
}
.board_nav .folder_title{
    font-weight: bold;
   cursor:pointer;
    color:#3b4863;
  
    
}
.board_nav .folder_title svg{
    margin-right:5px;
}
.board_nav .folder{
    margin-top:10px;
}

.fortune-container .luckysheet-sheet-area {
    position: relative !important;
}
.luckysheet-grid-window {
background:#fff !important;
}

#luckysheet-copy-paste{
    display: none !important;
}

.board_nav .FolderTree{
    margin-top:10px;
    margin-bottom: 20px;
    padding-bottom:20px;
    border-bottom: 1px solid #ddd;
}
.FolderTree svg{
    font-size: 13px;

}
.board_wrt{
    width:100%;
    background-color: #243863;
    text-align: center;
    padding:12px 0;
    color:#fff;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom:20px;
}
.board_wrt a{
    color:white;
}

.board_wrap{
    padding:30px;
    width:100%;
}

.bd-b {
    border-bottom: 1px solid rgba(72, 94, 144, 0.16);
}
.thead-dark {
    border-top: 3px solid rgba(72, 94, 144, 0.16);
}

.boardview tr th{
    padding:5px;
    border:1px solid #ddd;
    text-align: center;
    color:#c4cbff;
}
.boardview tr td{
    padding:5px;
    border:1px solid #ddd;
    text-align: center;
}

.board_toolbar_task_mobile{
    display: none;
    padding:20px;
    border-bottom: 1px solid #ddd;
}
.board_toolbar_task_mobile svg{
font-size:20px;cursor: pointer;
}
.board_toolbar_task_mobile .btn svg{
    font-size: 12px;
    
    color:#7987a1;
    margin-right:5px;

}
.board_toolbar_task_mobile span{
    font-size:13px;
}
.board_toolbar_task_mobile .menu{
    position: absolute;
    width:150px;
    right:0;
    top:120px;
    border:1px solid #ddd;
    background-color: white;
    z-index: 1000;
    box-shadow: 0 0 8px 2px rgba(28, 39, 60, 0.04);
}
.board_toolbar_task_mobile .menu .btn{
    padding:10px 15px;
    display: flex;
    align-items: center;
    cursor: pointer;
}
.board_toolbar_task_mobile .menu .btn:hover{
    background-color: #ddd;
}
.board_toolbar_task {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin: 0 25px;
    padding: 20px 10px 20px 10px;
    background-color: transparent;

    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

}
.board_toolbar_task .btn{
    border:1px solid #7282fe;
    padding:5px;
    cursor: pointer;
    color:#7282fe;
    margin-right:5px;
    display: flex;
    align-items: center;
}
.board_toolbar_task .btn:hover{
background-color: #7282fe;
color:white;
}
.board_toolbar_task .btn a{
    color:#7282fe;
}
.board_toolbar_task .btn a:hover{
    color:white;
}
.board_toolbar_task .btn svg{
    margin-right:5px;
}
.board_view_header{
    margin:0 25px;
    padding:0px 10px 20px 10px;
    box-sizing: border-box;
    border-bottom: 1px solid rgba(230, 230, 234, 0.7);
}
.board_view_header table{
    border-collapse: collapse;
    width:100%;
}
.board_view_header tr th, .board_view_header tr td{
    padding:8px;
    border:1px solid #ddd;
}
.board_view_header tr th{
    width:150px;
    background-color: #f5fcff;
    font-weight: normal;
    color:#0F366B;
    border-left:none;
}

.board_view_header tr td{
    border-right:none;
}
.board_view_header tr .cate_list{
    display: flex;
    flex-wrap: wrap;
}
.board_view_header tr .cate_list span{
background-color: #eee;
cursor: pointer;
border-radius: 5px;
padding:4px 8px;
margin-top:2px;
margin-bottom:2px;
margin-right:8px;

}
.board_view_header tr .cate_list span:hover{
    background-color: #7282fe;
    color:white;
}
.board_view_header tr .cate_list .selected{
    border: 1px solid #7282fe;
    color:#7282fe;
    background-color: white;
}
.table{
    border-collapse: collapse;
    width:100%;
    
}
.table .thead-dark th {
    color: #3b4863;
    border-bottom: 1px solid rgba(105, 121, 157, 0.587);
}
.table thead th {
    border-bottom-width: 0;
    line-height: 31px;
}
.table thead th, .table tbody th {
    font-weight: 500;
}
.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(72, 94, 144, 0.16);
}
.table tbody td {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table thead th:nth-of-type(1) {
    width:100px;
}
.table tbody td:nth-of-type(1) {
    text-align: center;
}
.board_list .table thead th:nth-of-type(2) {
    /*width:50%;*/

}

.board_list .table thead th:nth-of-type(3) {
    width: 12%;

}

.board_list .table thead th:nth-of-type(4) {
    width:10%;

}
.board_list .table thead th:nth-of-type(5) {
    width:10%;

}
.board_list .table tbody td:nth-of-type(6) {
    text-align: center;
}
.board_list .table thead th:nth-of-type(6) {
    width:5%;

}

.table tbody td span{
cursor:pointer;
}
.table tbody tr:hover {
background-color: #e8f7ff;
}

.table tbody td a {
    color:#000;
}

.board_top .fl_r > * {
    margin-right: 0;
    margin-left: 4px;
}
.board_top{
    width:100%;
    margin-top:20px; /*check*/
    text-align: right;
}
.board_top * {
    vertical-align: top;
}
.board_top .bt_srch{
    /*width: 35%;*/
    margin-bottom:20px;
}
.board_top .bt_srch > div {
    display: inline-block;
}
.board_top .bt_srch > div {
    margin-left: 10px;
      
}
.search_btn{
    width:90px;
    background: transparent;
    border:1px solid #7987a1;
    height:33px;
    padding:0;
    line-height: 33px;

}
.board_top .bt_srch select {
    height:33px;
    width:120px;
    line-height: 30px;
    text-align: center;
    border:1px solid #7987a1;
    color: #444;
}
.board_top input {
    height:30px;
    padding-left:10px;
    border:1px solid #ddd;
    
    width:180px;
    line-height: 30px;
    color: #444;
}
.board_top{
    display:flex;
    align-items: center;
    justify-content: space-between;
}
.noticeboard{
    background-color: #f7f7f7;
}

.noticeboard td:first-of-type span {
    background-color: #465e8e;
    color:white;
    padding:2px 5px;
}
.noticeboard td:nth-of-type(3) {
    font-weight: bold;

}
.noticeboard tbody td:nth-of-type(1){
    margin-bottom:20px;text-align: left;
}
.board_header{
    margin:0 0 20px;
    color:#0c385d;
    font-weight: bold;
}

/****************** AS 현황 *******************/
.as_status table{
    border-collapse: collapse;
}

.as_status .table_title2 {
    
    background-color: #f7f7f7;
    padding: 5px 5px 5px 15px;
}

.as_status .table_list2_left{
    padding:5px;
    border-bottom: none;
}

.as_status .table_list2_left input{
    border:none;
}
.as_status table tr{
    height:auto;
    border-top:1px solid #ddd;
    border-right:1px solid #ddd;
    
}

.as_status table tr:last-of-type{
    border-bottom:1px solid #ddd;
}

.as_status table tr td .input-select input{
    top:-7.5px;
    position:relative;
    
}
.as_status table tr td .input-group{
    width:100%;
}
.as_status table tr td .input-select svg{
    top:-7.5px;
    position:relative;
}
.as_list{
    width:100%;
    overflow-x: scroll;
    /*min-width: 1400px;*/
}
.delivery th span{
    cursor: pointer;
}

.delivery th span:hover{
    color:#7282fe;
}

.luckysheet-scrollbar-ltr{
    z-index:5 !important;
}

.cs_board .btns{

    margin:0 auto;
}
/*comment*/
.cmt .btns span{
    color:rgb(43, 43, 230);
    margin-left:5px;
    cursor: pointer;
}

@media (max-width: 600px) {

    .table tbody td {
        padding: 8px 0px;
    }
.board_toolbar_task{
    display: none;
}
.board_toolbar_task_mobile{
    display: flex;
    justify-content: space-between;
}
.board_view_header{
margin:20px 0 0 0 ;
}
.board_view_header tr th {
    width: auto;

}

.board_list thead tr th:nth-of-type(4),
.board_list td:nth-of-type(4),
.board_list thead tr th:nth-of-type(5),
.board_list td:nth-of-type(5) {
    display:none;


}


.board_list td:nth-of-type(2) span{
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:80px;
    display:block;
}


.board_list td:nth-of-type(3) a{
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width:150px;
    display:block;
}


}
@media (max-width: 1300px) {
    .board_header{
        margin:0px;
        margin-top:20px;
    }
    
    .board_wrap{
        padding:10px 0;
    }
    .board_nav {
        
        border-right:1px solid #ddd;
        background-color: #f7f7f7;
        
    }
    .folder-wrap{
        display:flex;
        flex-direction: row;
    }
    .board_nav .folder-wrap{
        flex-direction: column;
    }
    .board_list .table thead th:nth-of-type(1) {
        display:none;
    }
    .board_list .table tbody td:nth-of-type(1) {
        display:none;
    }

    .board_list .table tbody td:nth-of-type(3) {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        width:50px;
        padding:8px 0;
    }

  
    .board_list .table thead th:nth-of-type(3) {
        width: 4%;
        
    }

    .board_list .table thead th:nth-of-type(4) {
        width: 4%;
        
    }
    .board_list .table tbody td:nth-of-type(3) {
        overflow:hidden;
        text-overflow:ellipsis;
        white-space:nowrap;
        width:50px;
        padding:8px 0;
    }
    .board_top{
        flex-direction: column;
        width:100%;
        
    }
    .board_top .bt_srch{
        width:100%;
        display:flex;
        margin: 10px 0;
    }
    .cs .board_top .bt_srch{
        justify-content: center;
    }
    .board_top .bts_slct{
        width:20%;
    }
    .board_top .bt_srch select {
        width:100%;
    }
    .board_top .bt_input {
        width:55%;
       
        position: static;
    }
    .board_top .bt_input input{
        width:100%; 
        margin-left:0;
        padding-left:0;
    }
    .board_top .search_btn {
        width:17%;
        margin-left:3px;
    }

    .board_top .bt_srch > div {
        display: inline-block;
        font-size:12px;
    }
    .board_top .bt_srch > div:first-of-type{
        margin-left:0;
        width:100px;
    }
    .as_list{
        width:95%;
        
        
        
        overflow-x: scroll;
        min-width: unset;
    }
    .as_list:nth-of-type(2){
        top:0px;
        bottom:50px;
        position:relative;
        margin:0 auto 50px auto;
    }
    .cs_board .btns{
        margin:5px auto;

    }

    .cs_board .btns button{
        padding:5px 10px;
    }

    .cs_board .btns input{
        margin:0;
        margin-top:5px;
    }

    .cs_board .btns input{
        margin:0;
    }

    .cs_board .btns .right{
        display:none;
    }
}

.board_nav .list li{
    margin:15px 0;
}

.board_nav .list li span{
    margin-left:5px;
}
