
  
/*  .box {
    background: #fff;
    border-radius: 2px;
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.1);
    margin: 30px 5%;
    padding: 5%;
  }
  
  @media (min-width: 544px) {
    .box {
      margin: 40px auto;
      max-width: 440px;
      padding: 40px;
    }
  }
  
  
  /* The list style
  -------------------------------------------------------------- */
  .directory-list{

    border:1px solid #000;
    width:95%;
    height:350px;
    overflow-y : scroll;

  }
  .directory-list table {
    border-collapse: collapse;
    width:100%;
  }
  .directory-list table tr {
    cursor: pointer;
    width:100%;
  }
  .directory-list table tr:hover {
    color:#61affe;
  }

  .directory-list table tr td {
    border:1px solid #ddd;
  }

  .directory-list ul {
    margin-left: 10px;
    padding-left: 20px;
    border-left: 1px dashed #ddd;
  }
  .directory-list li{
    padding:5px 0;
    cursor: pointer;
  }
  .directory-list .folder {
    list-style: none;
    color: #888;
      
    position: relative;
    font-weight: normal;
  }
  
  .directory-list a {
    border-bottom: 1px solid transparent;
    color: #888;
    text-decoration: none;
    transition: all 0.2s ease;
  }
  
  .directory-list a:hover {
    border-color: #eee;
    color: #000;
  }
  
  .directory-list .folder,
  .directory-list .folder > a {
    color: #777;
  }
  
  .box_item, .list ul li span{
      
    cursor: pointer;
  }
  .box_item{
    margin-bottom:5px;
  }
  .box_item.clicked{
    font-weight:bold;
    color:#61affe;
  }
  .list ul li span:hover{
    background-color: #f7f7f7;
  }
  .box_item:hover{
    background-color: #f7f7f7;
  }
  /* The icons
  -------------------------------------------------------------- */

  .directory-wrap{
    display:flex;
    flex-direction: column;
    width:100%;
    justify-content: space-between;
  }
  
  .directory-list .folder span{
    margin-left:10px;
  }
  .directory-list .folder span:before {

    margin-right: 10px;
    content: "";
    height: 20px;
    vertical-align: middle;
    width: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='lightblue' d='M96.429,37.5v39.286c0,3.423-1.228,6.361-3.684,8.817c-2.455,2.455-5.395,3.683-8.816,3.683H16.071 c-3.423,0-6.362-1.228-8.817-3.683c-2.456-2.456-3.683-5.395-3.683-8.817V23.214c0-3.422,1.228-6.362,3.683-8.817 c2.455-2.456,5.394-3.683,8.817-3.683h17.857c3.422,0,6.362,1.228,8.817,3.683c2.455,2.455,3.683,5.395,3.683,8.817V25h37.5 c3.422,0,6.361,1.228,8.816,3.683C95.201,31.138,96.429,34.078,96.429,37.5z' /></svg>");
    background-position: center top;
    background-size: 75% auto;
  }
  .directory-list .folder:before {
    /*margin-right: 10px;
    content: "";
    height: 20px;
    vertical-align: middle;
    width: 20px;
    background-repeat: no-repeat;
    display: inline-block;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='lightgrey' d='M85.714,42.857V87.5c0,1.487-0.521,2.752-1.562,3.794c-1.042,1.041-2.308,1.562-3.795,1.562H19.643 c-1.488,0-2.753-0.521-3.794-1.562c-1.042-1.042-1.562-2.307-1.562-3.794v-75c0-1.487,0.521-2.752,1.562-3.794 c1.041-1.041,2.306-1.562,3.794-1.562H50V37.5c0,1.488,0.521,2.753,1.562,3.795s2.307,1.562,3.795,1.562H85.714z M85.546,35.714 H57.143V7.311c3.05,0.558,5.505,1.767,7.366,3.627l17.41,17.411C83.78,30.209,84.989,32.665,85.546,35.714z' /></svg>");
    background-position: center 2px;
    background-size: 60% auto;*/
    position: absolute;
        top:10px;
        left: 0;
        width: 10px;
        height: 1px;
        margin: auto;
        border:none;
        content: '';
        background-color: #000;
  }
  .directory-list .folder:after {
       position: absolute;
       border:none;
        top: 0px;
        bottom: 0;
        left: 0;
        /*width: 1px;
        height: 10px;*/
        
        content: '';
        background-color: #000;
  }
  .directory-list .folder:before {
    /* folder icon if folder class is specified */
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><path fill='lightblue' d='M96.429,37.5v39.286c0,3.423-1.228,6.361-3.684,8.817c-2.455,2.455-5.395,3.683-8.816,3.683H16.071 c-3.423,0-6.362-1.228-8.817-3.683c-2.456-2.456-3.683-5.395-3.683-8.817V23.214c0-3.422,1.228-6.362,3.683-8.817 c2.455-2.456,5.394-3.683,8.817-3.683h17.857c3.422,0,6.362,1.228,8.817,3.683c2.455,2.455,3.683,5.395,3.683,8.817V25h37.5 c3.422,0,6.361,1.228,8.816,3.683C95.201,31.138,96.429,34.078,96.429,37.5z' /></svg>");
    background-position: center top;
    background-size: 75% auto;
  }

.select .directory-wrap{
  flex-direction: row;
}
.select .directory-wrap .directory-list{
  width:50%;
  height:500px;
}
.select .directory-wrap .directory-select{
  width:47%;
}
@media (max-width: 690px) {
  .select .directory-wrap{
    flex-direction: column;
  }

  .select .directory-wrap .directory-list{
    width:100%;
    height:300px;
  }
  .select .directory-wrap .directory-select{
    width:100%;
  }

}