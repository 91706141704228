.list{
    margin-bottom:10px;
    
}
.list .menus{
    display:flex;
    flex-direction: column;
    padding-left:21px;
    width:100%;
    margin-top:5px;
    box-sizing: border-box;
    
}

.list .menus a{
    margin-bottom: 5px;
}
.list_title{
    font-weight:bold;

}

.list_title svg {
    margin-right: 5px;
}

.bookmarks span{
    cursor:pointer;
}

.bookmarks span:hover{
text-decoration: underline;

}


.menus .unread{
    background-color: #8f8f8f;
    color:#fff;
    border-radius: 4px;
    margin-left: 5px;
    padding: 2px 5px;
    font-size: 10px;
}

.menu_wrapper{
    padding:30px;
    background:#f7f7f7;
}