.row {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    }
    .row-middle {
    align-items: center;
    }
    .column {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    }
    /* Calendar */
    .calendar {
    display: block;
    position: static;
    width: 100%;
    background: white;
    border: 1px solid lightgray;
    height: auto;
    margin:0 auto;
    }
    .calendar .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid lightgray;
    }

    .calendar .body .row .cell:first-of-type {
        color:rgb(211, 111, 111);

    }
    .calendar .body .row .cell:last-of-type {
        color:rgb(100, 124, 246);

    }
    .calendar .header .icon {
    cursor: pointer;
    width:20px;
    transition: .15s ease-out;
    }
    .calendar .header .icon:hover {
    /*transform: scale(1.75);
    transition: .25s ease-out;*/
    color: dodgerblue;
    }
    .calendar .header .column:first-of-type .icon {
    margin-left: 1em;
    height:100%;
    display: flex;
    align-items: center;
    }
    .calendar .header .column:last-of-type .icon {
    margin-right: 1em;
    height:100%;
    display: flex;
    align-items: center;
    }

    .col-end{
        justify-content: end;
        display: flex;
    }
    .calendar .days {
    text-transform: uppercase;
    font-weight: 400;
    color: gray;
    background-color: #fafafa;
    font-size: 70%;
    padding: .75em 0;
    border-bottom: 1px solid lightgray;
    }
    .calendar .days .column{
        padding-left:5px;
        border-left: 1px solid #ddd;
    }
    .calendar .body .cell {
    position: static;
    min-height: 9em;
    height:auto;
    border-right: 1px solid lightgray;
    overflow: hidden;
    
    background: white;
    transition: 0.25s ease-out;
    }
    
    .calendar .body .cell:hover {
    /*background: whitesmoke;*/
    transition: 0.5s ease-out;
    }
    .calendar .body .selected {
    background-color: #ebf3ff;
    }
    .calendar .body .row {
    border-bottom: 1px solid lightgray;
    }
    .calendar .body .row:last-child {
    border-bottom: none;
    }
    .calendar .body .cell:last-child {
    border-right: none;
    }
    .calendar .body .cell .number {
    font-size: 82.5%;
    line-height: 1;
    padding:5px;
    top: .75em;
    left: .75em;
    font-weight: 700;
    }
    .calendar .body .selected .number   {
        color:#3cc48b;
    }
    .calendar .body .disabled {
    color: lightgray;
    opacity: 30%;
    pointer-events: none;
    }
    .calendar .body .cell .bg {
    font-weight: 700;
    line-height: 1;
    color: #1affa0;
    opacity: 0;
    font-size: 5em;
    position: absolute;
    top: -.2em;
    right: -.05em;
    transition: .25s ease-out;
    letter-spacing: -.07em;
    }
    
    .calendar .body .column {
    flex-grow: 0;
    flex-basis: calc(99%/7); /* 99.7% */
    width: calc(99%/7);
    }

    .calendar .schedule_list, .calendar .leave_list{
        display:flex;
        margin-bottom:5px;
        width:100%;
        justify-content: space-between;
        cursor: pointer;
        padding:0 5px;
    }
    .calendar .schedule_list:hover{
        background-color: whitesmoke;
    }
    .calendar .schedule_list svg{
        margin-right:5px;
    }

    .calendar .schedule_list .all{
        color:rgb(125, 125, 222);
    }
    .calendar .schedule_list span{
        margin-right:10px;
    }
    .calendar .schedule_list .allday{
        width:100%;
        color:white;
        background-color: rgb(125, 125, 222);
        padding:5px;
    }

    .calendar .leave_list svg{
        margin-right:5px;
        color:aqua;
    }
    .schedule button{
        display:flex;
        
        align-items: center;
        justify-content: center;
    }
    .schedule .bt_srch{
        display:flex;
    }
    .schedule .left{
        display:flex;
        flex-grow: 0;
    }
    .schedule .left .btn{
        height:40px;
        margin-right:5px;
    }
    .schedule table td{
        text-align: center;
    }

    /**** week table ****/

    .tg  {border-collapse:collapse;border-spacing:0;width:100%;}
.tg td{border-color:#ddd;border-style:solid;border-width:1px;font-family:Arial, sans-serif;  
  overflow:hidden;padding:10px 5px;word-break:normal;}
.tg th{border-color:#ddd;border-style:solid;border-width:1px;font-family:Arial, sans-serif;  
  font-weight:normal;overflow:hidden;padding:10px 5px;word-break:normal;}
.tg .tg-0lax{text-align:left;vertical-align:top;height: auto;padding:10px 20px;}

.tg .tg-0lax:first-of-type{
    width:30%;
}

.tg-0lax li{display:flex; align-items: center; margin-bottom:8px;}

.tg .selected{
    background-color: #ebf3ff;
}
.calendar .body .cell.delivery_date {
    background-color: #ffe699;
 }

 
 .calendar .delivery_schedule{
    display:flex;
    flex-direction: column;
}
.calendar .delivery_schedule span{
    width:100%;
    margin-top:5px;
}
.calendar .body .cell.delivery_date{
    background-color: #ecf4ff;
    cursor: pointer;
}

 
 

 
.tg-0lax li span:before{
    content:"\00a0\00a0\00a0\00a0\00a0";
    text-decoration:line-through;
    color:#ddd;
    margin-left:15px;
}



/******** calendar add ********/

.react-colorful{
    position:absolute !important;
    z-index:9999;
    top:40px;
    left:100px;
}

.schedule .btns {
    width: 100%;
    display: flex;
    margin: 0px 0px 20px 0px;
    flex-direction: row;
    justify-content: space-between;
}

@media (max-width: 1300px) {

    .calendar_setting .form_write .table_title2 {
        width:120px;
    }
.calendar .body .column {
    flex-grow: 0;
    flex-basis: calc(99%/7.1);
    width: calc(99%/7.1);
}
.col-start{
    width:20px;
    flex-grow: 0;
}
.col-end{
    width:20px;
    flex-grow: 0;
}
.calendar .body .cell {
    min-height:8em;
    border:none;
   
}
.schedule_list{
    font-size:10px !important;
    flex-direction: column;
}
.calendar .body .cell svg{
    display:none;
}
.main_content{
    box-sizing: border-box;
    width: 100%;
    margin: 0px auto;
    padding: 0px 5px;
}
.schedule .btns{
    flex-direction: column;
    align-items: start;
    margin:0;

}
.schedule .btns .right{
    margin:20px 0;
}
.schedule .bt_srch{
    margin:10px 0;
}
.schedule .board_top .bt_srch > div {
margin-left:0px;

}

.schedule .search_btn{
    width:20%;
}

.schedule .bts_slct{
    width:30%;
    margin-left:0;
}
.schedule .board_top .bt_srch select {
    width:100%;
}
.schedule .bt_input{
    width:50%;
}
.schedule .board_top input{
    width:100%;
    padding-left:0px;
    margin:0;
}
.schedule .btns .btn, .btns button {
padding:5px;
}

}

@media (max-width: 700px) {

    .list_leave{
        flex-direction: column;
    }
    .react-colorful{
        left:-110px;
    }

.calendar_setting .form_write tr{
    flex-direction: column;
}
.calendar_setting .form_write .table_title2 {
    width:100%;
    padding:5px 0;
}

.calendar .schedule_list, .calendar .leave_list{
    padding:0;
}
}