
.directory-search{
    display:flex;
}
.search-word span{
    cursor:pointer;
}

.search-word span:hover{
    background-color: aqua;
}

.directory-search{
    margin:23px auto;
}
.directory-search input{
    width:120px;
    
}



.directory-btn .directory-btns{
    height:220px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}
.directory-btns svg{
    font-size:20px;
    margin-right:5px;
    color:#8aa3ee;
}
.directory-wrap .title{
    height:30px;
    display: flex;
    align-items: start;
    font-weight: bold;
    color:#0a2d5e;

}
.directory-wrap{
    margin-left:10px;
}
.directory-wrap .sub-title{
    height:30px;
    display: flex;
    align-items: center;
}

.directory-select select{
    width:100%;
    height:180px;
    border:1px solid #696969;
}



.customer thead th:nth-of-type(2), .customer tbody td:nth-of-type(2){
    width:12%
}

.customer thead th:nth-of-type(3), .customer tbody td:nth-of-type(3){
    width:10%
}

.customer thead th:nth-of-type(4), .customer tbody td:nth-of-type(4){
    width:6%
}
.customer thead th:nth-of-type(5), .customer tbody td:nth-of-type(5){
    width:6%
}

.customer thead th:nth-of-type(6), .customer tbody td:nth-of-type(6){
    width:7%
}

.customer thead th:nth-of-type(7), .customer tbody td:nth-of-type(7){
    width:7%
}

.customer thead th:nth-of-type(8), .customer tbody td:nth-of-type(8){
    width:25%
}
.customer thead th:nth-of-type(9), .customer tbody td:nth-of-type(9){
    width:25%
}

@media screen and (max-width: 1850px) {



    .customer thead th:nth-of-type(2), .customer tbody td:nth-of-type(2){
        width:12%
    }
    
    .customer thead th:nth-of-type(3), .customer tbody td:nth-of-type(3){
        width:10%
    }
    
    .customer thead th:nth-of-type(4), .customer tbody td:nth-of-type(4){
        width:6%
    }
    .customer thead th:nth-of-type(5), .customer tbody td:nth-of-type(5){
        width:6%
    }
    
    .customer thead th:nth-of-type(6), .customer tbody td:nth-of-type(6){
        width:12%
    }
    
    .customer thead th:nth-of-type(7), .customer tbody td:nth-of-type(7){
        width:12%
    }
    
    .customer thead th:nth-of-type(8), .customer tbody td:nth-of-type(8){
        width:18%
    }
    .customer thead th:nth-of-type(9), .customer tbody td:nth-of-type(9){
        width:18%
    }
    




}
@media screen and (max-width: 900px) {


.cx_table .table thead th:nth-of-type(2), .cx_table .table tbody td:nth-of-type(2){
    width:45%;
}
.cx_table .table thead th:nth-of-type(3), .cx_table .table tbody td:nth-of-type(3){
    width:30%;
}

.customer thead th:nth-of-type(5), .customer tbody td:nth-of-type(5){
    width:25%
}


.cx_table .table thead th:nth-of-type(4), .cx_table .table tbody td:nth-of-type(4),
.cx_table .table thead th:nth-of-type(6), .cx_table .table tbody td:nth-of-type(6),
.cx_table .table thead th:nth-of-type(7), .cx_table .table tbody td:nth-of-type(7),
.cx_table .table thead th:nth-of-type(8), .cx_table .table tbody td:nth-of-type(8),
.cx_table .table thead th:nth-of-type(9), .cx_table .table tbody td:nth-of-type(9) {
    display:none; 
}

}
