.sidenav .list{
    font-size: 13px;
    padding:0 30px;
    margin-top:20px;
}

.separated {
    margin-top: 4px;
    padding-top: 5px;
    border-top: 1px solid #f7f7f7;
}

.admin_sidenav {
    height: 100%;
    width: 230px;
    position: fixed;
    z-index: 1;
    
    left: 0;
    overflow-x: hidden;
    background-color: #4c6ddc;
  }
  

  /*.write_btn{
    width:100%;
    text-align: center;
    display: flex;
    border-bottom: 1px solid #4c6ddc;
  }
  .write_btn a{
    padding:15px 20px;
      
    width:50%;
    
    
    
  }*/
  .write_btn a:first-of-type{
    border-right: 1px solid #ddd;
  }
 