.bookmarks{
    margin-top:10px;
}
.bookmarks span{
      
    margin-bottom:5px;
    margin-left:2px;
    display:flex;
    
}
.bookmarks span svg{
    margin-right:5px;
    color:rgb(50, 99, 213);
    
}