.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index:11;
  }

  .boardwrite .popup {
    z-index:21;
  }
  .popup_inner {
    position: absolute;
    left: 25%;
    right: 25%;
    top: 25%;
    bottom: 25%;
    margin: auto;
    width:500px;
    height:750px;
    background: white;
  
    text-align: center;
  }
  .popup .inner{
    background-color: white;
    padding:20px;
  
  }
  .popup .inner table, .popup .inner table td{
    border-collapse: collapse;
    text-align: center;
    padding:5px 10px;
    border:1px solid #ddd;
  }
  .popup .inner button{
    width:70px;position: relative;
    left:50%;transform: translateX(-50%);
  }
  .popup .inner table th{
    background-color: #eee;
  }

  

  .delivery_write{
    box-sizing: border-box;
  }


  .delivery_write span{
    width:30px;
    background-color: #bbc4f2;
    display: flex;
    align-items: center;
    justify-content: center;
    color:white;
    border-bottom: 1px solid white;
    cursor: pointer;
    font-weight: normal;
  }
  .delivery_write input{
    border: 1px solid #ddd !important;
    width: 100%;
    height:20px;
  }

  .addUser{
  }
.addpic{
    display:flex;justify-content: center;
    margin-bottom:10px;
}
.addInfo{
    display:flex;
    flex-direction: column;
    text-align: left;
    width:70%;
    margin:0 auto;
}
.addInfo input, 
.addInfo select{
  border:1px solid #c0c0c0;
  height:20px;
  width:100%;
}

.addInfo span{
  text-align: center;
  font-size:13px;
  margin-top:0px;
  color:#7e7e7e;
}
  .custom-file-upload {
    display:block;
   position:relative;
   
  }
  .custom-file-upload img{
    border-radius:50%;
    width:150px;
    height:150px;
    object-fit: cover;
    border:2px solid #d8defd;
  }
  .custom-file-upload input[type="file"] {
    display:none;
  }
  .img-wrap{
    position: relative;
    width:100%;
    height:100%;
    display: flex;
    flex-direction: column;

  }
.buttons{
    width:140px;
    position: absolute;
    left: 0;
right: 0;
margin: 20px auto;
    display:flex;
    justify-content: center;
    
}

.custom-file-upload button{
  width:80px;
  margin:10px auto 0px auto;
  padding:5px 15px;
  cursor: pointer;
}
.custom-file-upload button{
  background-color: white;
  cursor: pointer;
  border: 1px solid #bfbfc2;
  padding: 5px 20px;

}

.user_input{
  display:flex;
  margin-top:12px;
}
.user_input span{
    
  margin-left:10px;
  cursor: pointer;
}
.user_input span:hover{
  color:#7b8fff;
}
.user_input label{
width:35%;
  
}
.user_input svg{
  color:#bebebe;
  margin-left: 5px;
  cursor: pointer;
}
.user_input .react-datepicker-wrapper input[type=text]{
  height:20px !important;
  padding: 1px 2px !important;
}
.user_input .react-datepicker-wrapper{
  width:auto;
}
.addpic svg{
  display:block;
  font-size: 50px;
  color:#ddd;
}

.buttons button:first-of-type{
  background-color: #7b8fff !important;
  color:#fff;
  border:none !important;
}


/**** Department ****/

.add_department table th{
  background-color: #eee;
  font-weight: normal;
  width:120px;
    
}
.add_department table td{
  border-bottom:1px solid #eee;
  padding: 10px;
  text-align: left;
}

.add_department table td input{
  border:1px solid #a6a6a6;
  width:95%;
  margin:0 auto;
    
  text-align: left;
}
.add_department table{
  border-collapse: collapse;
  border:1px solid #eee;
}
.department{
  height:300px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; 

}

.FolderTree .EditableName .displayName:focus {
  color: #4d94ff;
  cursor: pointer;
}

select:focus, input:focus{
  outline: none;
}
.send_work .inner{
  width:700px;
}

.leave_history td{
  padding:0;
}



.leave_history th:first-of-type{
  width:17%;
}
.leave_history th:nth-of-type(2){
  width:20px;
}
.leave_history th:nth-of-type(3){
  width:10px;
}
.leave_history th:nth-of-type(4){
  width:100px;
}
.leave_history th:nth-of-type(5){
  width:30px;
}
.leave_history th:nth-of-type(6){
  width:10px;
}
.leave_history th:nth-of-type(7){
  width:10px;
}


@media (max-width: 1300px) {
  .popup_inner {
    left:0;right:0;
    top:0;bottom: 0;
    width:95%;
  }
  .send_work .inner{
    width:95%;
  
  }

  
.leave_history th:first-of-type{
  width:30%;
}
.leave_history th:nth-of-type(2){
  width:100px;
}
.leave_history th:nth-of-type(3){
  width:50px;
}
.leave_history th:nth-of-type(4){
  width:30%;
}
.leave_history th:nth-of-type(5), .leave_history td:nth-of-type(5){
  display: none;
}
.leave_history th:nth-of-type(6){
  width:30px;
}
.leave_history th:nth-of-type(7){
  width:30px;
}

}

