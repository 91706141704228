#list_table_json{
  width:90%;
  margin:0 auto;
  display:flex;
  flex-direction: column;
}
.emails{
  display:flex;
  width:100%;
  padding:8px 10px;
}
.froms{
  width:50px;
  min-width:200px;
  word-wrap: break-word;

  overflow:hidden;
  white-space:nowrap;
  text-overflow: ellipsis;
}
.subjects{
  width:80%;
  min-width:350px;
}
.subjects svg{
  color:#70b8ff;
}


.email_item .fa-star{
  margin-top:9px;
}


.email_item input{
  width:13px;
  height:13px;  overflow:hidden;
  margin-top:9px;
}

.subjects span{
  margin-left:10px;
  width:100%;
  text-overflow: ellipsis;
  overflow: hidden;
  /*white-space: nowrap;*/
}
.dates{
  width:10%;
  min-width:200px;
  text-align: right;
}

.read_date{
  display:flex;
  align-items:center;
  right:30px;
  position: absolute;
}


a{
  text-decoration: none;
  color:black;
}
a:hover{
  background-color: none;
}





.email_item{
  margin: 0;
  display:flex;
  /*align-items: center;*/
  border-bottom: 1px solid rgba(230, 230, 234, 1);
  width:100%;
}
.email_item a{
  width:100%;
}
.email_item a:hover{
  background-color: transparent;
}

.email_item .subjects span:hover{
  font-weight: bold;
  text-decoration: underline;
}
 
/*NAVER*/

.mail_toolbar {
  -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        position: relative;
        top: auto;
        right: auto;
        left: auto;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        margin:0 20px;
        border-top:1px solid #ddd;
        border-bottom:1px solid #ddd;
        padding:15px 0;
        position:relative;
    }

    .mail_toolbar_count {
      display: none;
      -webkit-box-flex: 0;
      -webkit-flex: 0 1 auto;
      -ms-flex: 0 1 auto;
      flex: 0 1 auto;
      -webkit-box-ordinal-group: 2;
      -webkit-order: 1;
      -ms-flex-order: 1;
      order: 1;
      position: relative;
      min-width: 0;
      margin-left: 17px;
      padding: 0;
      background-color: transparent;
      line-height: 22px;
  }
  .mail_toolbar_count {
      padding: 7px 0 13px;
      background-color: #282b36;
      font-size: 0;
      text-align: center;
  }

  .mail_toolbar_task {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-right: -29px;
    padding: 13px 0 12px;
    background-color: transparent;
}
.mail_toolbar_task {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0 16px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}
.mail_toolbar_task_main{
  display: flex;
  flex-wrap: wrap;
 
}
.mail_toolbar_task_main .button_group{
  display: flex;
  align-items: center;
}
.mail_toolbar_task .button_group {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.mail_toolbar_task button{
  background-color: transparent;
  border:none;
  cursor: pointer;
  
  padding:3px 5px;
  margin-right:2px;
  
}

.mail_toolbar_task_main button{
  background-color: transparent;
  cursor: pointer;
  padding:3px 5px;
  margin-right:2px;
  border:none;

}

.mail_toolbar .button_select{
  display:flex;
  align-items: center;
}
.mail_toolbar_task button span{
  font-size:12px;
  line-height: 20px;
}
.mail_toolbar_task button:hover{
  background-color: #eee;
}

/*** NAVER CONTENTS HEADER ***/
.contents_header {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height:70px;
  margin:0 20px;
}

.contents_header .title_area {
  display:flex;
  padding: 21px 15px 22px 35px;
}

.contents_header .title_area .mailbox_title {
  font-size: 16px;
}

.contents_header .title_area .unread_mail_link {
  font-weight: 600;
  color: #0073e6;
}

.contents_header .title_area .button_wrap {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  position: relative;
  display: block;
  padding: 3px 0;
}



.mail_content{
  
  width:95%;
  padding:10px;
  min-width:810px;
  margin:0 auto;

}

.mail_content .table_list2_left{
width:100%;


}
.email_list{
  margin:0 20px;
}
/* The list style
-------------------------------------------------------------- */


.fa-star{
  margin-left: 5px;
  cursor: pointer;
}
.star{
  color:#6e6e6e;
}
.nostar{
  color:#fff;
  
}

.fa-star path{
  stroke: #6e6e6e;
  stroke-width:15px;
  stroke-linejoin: round;
}
.mail_content .btns{
  display: flex; align-items: baseline; width: 95%; margin: 0px auto;
}
.mail_content .btns .mail_header{
  display: flex; align-items: center;
}
.mail_toolbar_task{
  flex-wrap: wrap;
}
.break {
  display:none;
  
}
@media (max-width: 1300px) {
 
  .mail_toolbar_task_main{
    width:100%;
    position:relative;
    justify-content: start;
  }
  .read_date{
    position: relative;
    text-align: right;
    margin-left: 40px;
    margin-top: 5px;
  }

  .emails{
    position: relative;
  }
  .email_list{
    margin:0;
    font-size:12px;
  }
  .mail_toolbar{
    width:100%;
    margin:0;
    margin-top:20px;
  }
  
  .mail_toolbar_task{
    margin-left:0 !important;
  }
  .mail_toolbar_task :nth-child(7){
    margin-left:20px;
  }
  .contents_header{
    margin:0;
    flex-direction: column;
  }
  .mail_content{
    min-width:unset;
  }
  .mail_content .btns{
    width:100%;
  }
  .mail_content .btns .mail_header{
    text-align: left; justify-content: start;
  }
  .email_item a{
    width:95%;
  }
  .email_item .emails{
  display:Flex;
  flex-direction: column;
  box-sizing: border-box;
  width:100%;
 }

  .email_item .emails .subjects{
    margin-top:5px;
    min-width: unset;
    width:100%;
  }
  .email_item .emails .dates{
    position: absolute;
    right:10px;
    width:auto;
    min-width: unset;
  } 
  .dashboard .froms{
    width:30%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width:unset;
  }
  .dashboard .email_list a{
    width:50%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    min-width:unset;
  }
  .dashboard .subjects{
    min-width:unset;
    width:100%;
  }
  .dashboard .dates{
    width:20%;
    min-width:unset;
  }
}

.button_task_wrap{
  width:auto;
}
@media (max-width: 700px) {

.break {
  display:flex;
  flex-basis: 100%;
  height: 0;
}
.mail_toolbar_task_main{
  display:none;
}
.email_list .email_item input{
  display:none;
}

}