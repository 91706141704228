.level_table {
    border: 1px #ddd solid;
    font-size: .9em;
    
    width: 100%;
    border-collapse: collapse;
    
    overflow: scroll;
  }
  .level_table td, .level_table tr{
    border: 1px #ddd solid;
  }
  .level_table th {
    text-align: left;
  }
    
  .level_table thead tr td{
    font-weight: bold;
    color: #fff;
    background: #7f86929a;
  }
    
  .level_table td,.level_table th {
    padding: 0.5em .5em;
    vertical-align: middle;
  }
    
  .level_table td {
    border-bottom: 1px solid rgba(0,0,0,.1);
    background: #fff;
    height:20px;
  }
  .level_table td:first-of-type{
    width:10px;
  }
  .level_table td:nth-of-type(2){
    width:20%;
  }
  .level_table td:nth-of-type(3){
    width:50%;
  }
  .level_table a {
    color: #73685d;
  }
  
.admin_auth .level_table tr td{
  padding:10px 0;
}
.admin_auth .subtable tr{
  border:none;
}
.admin_auth .subtable td{
  background-color: #fafdff;
  border:none;
  cursor: pointer;
}

.admin_auth .level_table tr td:hover, .admin_auth .subtable td:hover{
  background-color: #cdf3ff;cursor: pointer;
}
.admin_leave{
  min-width:1200px;
}

@media screen and (max-width: 1500px) {
  .table_wrap{
    /*overflow-y: scroll;
    height:500px;*/
  }
  .admin_leave{
    min-width:unset;
  }
  .admin_leave table {
    border: 0;
  }

  .admin_leave table caption {
    font-size: 1.3em;
  }
  
  .admin_leave table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .admin_leave table tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
  }
  
  .admin_leave table td {
    border-bottom: 1px solid #ddd;
    display: block;
    /*font-size: .8em;*/
    text-align: right;
  }
  
  .admin_leave table td::before {
    /*
    * aria-label has no advantage, it won't be read inside a table
    content: attr(aria-label);
    */
    
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  .admin_leave table td:last-child {
    border-bottom: 0;
  }




}
