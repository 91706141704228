.dropdown-container {
    text-align: left;
    border: 1px solid #ccc;
    border-radius: 5px;
    width:40%;
    height:28px;
    
    font-size:13px;
  }
  
  .dropdown-input {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }
  .dropdown-menu{
    z-index:9999;
    box-shadow: 0 2px 4px 0 rgba(99, 101, 112, 0.5);
    position:relative;
    background-color: white;

  }
  .dropdown-item{
    padding:5px;
    cursor:pointer;
  }

  .dropdown-item:hover{
    background-color: #9fc3f870;
  }

.dropdown_div{
    position: absolute;
    max-height: 600px;
    width: 244px;
    display: flex;
    flex-direction: column;
    background-color: white;
    justify-content: center;
    z-index: 9999;padding : 10px 0;
    box-shadow: 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%), 0px 5px 5px -3px rgb(0 0 0 / 20%);
}
.dropdown_div button{
  padding:5px 10px;
  width:70px;
  margin:10px auto 0px auto;
  background-color: white;
  border:1px solid #ddd;
  cursor:pointer;

}
.parent-list {
  list-style-type : none;
  margin : 0;
  
}

.parent-item {
  color : grey;
  border : solid 1px transparent;
  font-family : Arial, sans-serif;
}
.parent-item .item-wrap{
  width:100%;
  border:none;
  height:25px;
  display: flex;
  background-color: white;
  text-align: left;
}
.item-wrap label{
  display: block;
  cursor:pointer;
  height:100%;
    
  padding-left:10px;
  width:100%;
  line-height: 25px;
}
.child-item {
  display: flex;
}
.child-item .item-wrap label{
  padding-left: 20px;
}
.child-item .item-wrap input[type="radio"]{
  display: none;
}
.child-item .item-wrap input[type="radio"]:checked+label {
  background-color: #eee;
  color:#3655f5;
}
.parent-item label:hover {
  
  color : black;
  background-color : lightblue;
  cursor : pointer;
}
