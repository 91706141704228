.main_content{
    background-color: white;
    padding:0 30px;
}

.doc_main .row{
    display:flex;
    justify-content: flex-start;
}
.doc_main .box svg{
    margin-left:5px;
    color:#6fa5d1;
}
.doc_main .box {
    background: #ffffff;
    border:1px solid #6fa5d1;
    border-radius: 2px;
    margin-right:5px;
    
    cursor: pointer;
    display:flex;
    align-items: center;
 
}
.doc_main .box a{
    
    padding: 20px 30px;
}
.doc_main .box a:hover{
    background-color: #6fa5d1;
    color:white;
}
.doc_main .box:hover svg{
    color:white;
}
.doc_main .table{
    width:95%;
    margin:0 auto;
}
.doc_main .table tr:first-of-type{

    background-color: #e8f7ff;

}
.doc_line{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.doc_line .user{
    
    display:flex;
    align-items: center;
}
.doc_line .user span{
    display:flex;
    border:2px solid #6fa5d1;
    padding:5px 10px;
    align-items: center;
}
.doc_line .user .wait{
    border:2px solid #d16f98;
}
.doc_line .user .wait svg{
    color:#d16f98;
}
.doc_line .user svg{
    color:#6fa5d1;
    margin-right:5px;
}

div:has(.form_content){
    width:100%;
}
/******** Doc Admin ********/

.doc_admin .field{
    display:flex;
    align-items: center;
    border-right:1px solid #ddd;
    padding-right:20px;
}
.doc_admin .field input{
    margin-right:0;
}
.doc_admin .field p {
    margin: 0 20px;
}

.doc_admin .field select {
    height: 33px;
    width: 120px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #ddd;
    color: #444;
}

.doc_buttons{
    display:flex;
}
.doc_buttons .btn, .doc_buttons a{
    margin-right:10px;
    display:flex;
    padding:5px 10px;
    align-items: center;
    border:1px solid #6fa5d1;
    color:#6fa5d1;
    cursor: pointer;

}
.doc_buttons .btn svg{
    margin-right:5px;
    font-size: 17px;
    color:#6fa5d1;
}
.doc .table td{
    text-align: center;
}


/***** SETTINGS *****/

.doc-setting .input-group .img-wrap{
    margin:10px;
}
.doc-setting .btns{
    margin:10px 0;
}
.doc_header{
    display: flex; align-items: center; justify-content: space-between;
}

.pc {
    display:flex;
}

.docs_view{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 95%;
    margin: 0px 50px;
    max-width: 1600px;
}

.ot tr{
    display:flex;
    
}

.ot .table_title2 {
    width:100px !important;
}

.ot .table_list2_left{
    margin-left:0px;
}

.main_content{
    width:90%;
    height:100%;
    /*height:100vh;*/
    /*margin:30px auto;*/
}
.doc_admin .btn {
    margin-left:20px;
}

.doc_list table th:nth-of-type(1),.doc_list table td:nth-of-type(1){
    width:5%;
}


.doc_list table th:nth-of-type(2),.doc_list table td:nth-of-type(2){
    width:10%;
}


.doc_list table th:nth-of-type(3),.doc_list table td:nth-of-type(3){
    width:10%;
}


.doc_list table th:nth-of-type(4),.doc_list table td:nth-of-type(4){
    width:45%;
}
.doc_list table th:nth-of-type(5),.doc_list table td:nth-of-type(5){
    width:10%;
}
.doc_list table th:nth-of-type(6),.doc_list table td:nth-of-type(6){
    width:10%;
}
.doc_list table th:nth-of-type(7),.doc_list table td:nth-of-type(7){
    width:10%;
}


.input-group-file{
    display: flex;
    flex-direction: column;
    align-items: start !important;
    padding: 10px;
}
.input-group-file div{
    margin-bottom: 10px;
}


@media (max-width: 1300px) {
    .doc_list table th:nth-of-type(1),.doc_list table td:nth-of-type(1){
        width:unset;
    }
    
    
    .doc_list table th:nth-of-type(2),.doc_list table td:nth-of-type(2){
        width:unset;
    }
    
    
    .doc_list td:nth-of-type(3){
        width:unset
    }
    .doc_list table th:nth-of-type(3){
        width:70px !important;
    }
    
    .doc_list table th:nth-of-type(4), .doc_list table td:nth-of-type(4){
        width:unset;
    }
    .doc_list td:nth-of-type(5){
        width:unset;
    }
    .doc_list table th:nth-of-type(5){
        width:110px;
    }
    .doc_list table th:nth-of-type(6),.doc_list table td:nth-of-type(6){
        width:unset;
    }
    .doc_list table th:nth-of-type(7),.doc_list table td:nth-of-type(7){
        width:unset;
    }
    
    
    
    
    
    
    
    .doc_admin .btns{
        flex-direction: column;
    }
    .doc_admin .field {
        border:none;
        margin-top:5px;
    }
    .doc_admin .field p{
        width:70px;
        margin:0;
        text-align: left;
    }

    .doc_admin .field input {
        width:150px;
        margin: 0;
    }

    .doc_admin .field button {
        margin-left: 5px;padding: 6px 7px;
        
    }
    .doc_admin .btn {
        width:20%;
        margin:20px auto 0 auto;

    }
    .doc_list{
        margin-top:20px;
    }
    .doc_main .box a{
        padding:5px 10px;
    }
    .doc_main .btns{
        
        width: 100%;
        
    }

    .doc_header{
        flex-direction:column;
        align-items: start;
    }
    .pc {
        display:none !important;
    }
    
    .docs_view{
        width:100%;
        margin:0;
    }

    

    .leave_view tr{
        width:100%;
        margin-bottom: 5px;
    }
    .leave_view tr td {
        display:flex;
        margin:0 2px;
        height:auto;
        min-height: 10px;
        width: 100%;
        padding: 5px 10px;
        text-align: justify;
        flex-direction: column;
    }




    .leave_view tr td .input-group input {
        padding:0;


    }

   
   
    .leave_view tr td svg{
        display:none;
    }
    .main_content{
        width:95%;
        padding:0 0 50px 0;
        
        margin:0 auto;

    }
    
}


.doc_top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media (max-width: 700px) {
    .doc_buttons{
        display:none;
    }

    /*.doc_list .table tbody td:nth-of-type(5),*/
    .doc_list .table thead th:nth-of-type(1), .doc_list .table tbody td:nth-of-type(1), 
    .doc_list .table thead th:nth-of-type(6), .doc_list .table tbody td:nth-of-type(6),
    .doc_list .table thead th:nth-of-type(7), .doc_list .table tbody td:nth-of-type(7),
    .doc_list .table thead th:nth-of-type(8), .doc_list .table tbody td:nth-of-type(8),
    .doc_list .table thead th:nth-of-type(9), .doc_list .table tbody td:nth-of-type(9) {
        display:none; 
    }
    
    .doc_admin .table thead th:nth-of-type(1), .doc_admin .table tbody td:nth-of-type(1),
    .doc_admin .table thead th:nth-of-type(5), .doc_admin .table tbody td:nth-of-type(5),
    .doc_admin .table thead th:nth-of-type(6), .doc_admin .table tbody td:nth-of-type(6),
    .doc_admin .table thead th:nth-of-type(7), .doc_admin .table tbody td:nth-of-type(7),
    .doc_admin .table thead th:nth-of-type(8), .doc_admin .table tbody td:nth-of-type(8),
    .doc_admin .table thead th:nth-of-type(9), .doc_admin .table tbody td:nth-of-type(9) {
        display:none; 
    }
    .doc_top{
        flex-direction:column;
    }
.doc_buttons .btn, .doc_buttons a{
    margin-right:0;
    padding:5px;
}
}

.input-group{
 width:100%;
}