.employee {
    position: fixed;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    
    margin: auto;
    z-index:9999;
  }


  .employee_inner {
    position: absolute;
    left: 0%;
    width:100%;
    top: 50px;
    background-color: #fff;
    height:597px;
    margin: auto;
    border:1px solid #000;
      
    text-align: center;
  }

  .dashboard-wrap .employee_inner {
    top:100px;
    border:1px solid #ddd;
  }
  .employee_inner .title{
    height:35px;
    width:100%;
    display:flex;
    align-items: center;
    justify-content: center;
    color:white;
    background-color: rgb(52, 96, 208);
  }
  .org .directory-list, .org select{
    width:100% !important;
  }
  .employee_inner .addUser{
    margin-top:20px;
  }
  .employee_inner .user_input label{
    width:30%;
  }

  @media (max-width: 580px) {
    .employee {
      
      width: 100%;
     
    }



  }