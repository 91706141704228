.checkline table{
    border-collapse: collapse;
}

.checkline table tr td{
    border:1px solid #ddd;
    padding:10px 0;
}


