.board_setting{
    background-color: #fff;
    margin:30px 0;
    padding:20px;
    box-sizing: border-box;
    width:55%;
}

.board_info{
    display:flex;
    margin-bottom:25px;
}

.board_info label{
    width:20%;
    color:#3b4d87;
    display: flex;
    align-items: center;
    
}

.board_info input[type="text"]{
    border: 1px solid #ddd;
    height:22px;
    width:80%;
}

.board_info select{
    border: 1px solid #ddd;
    height:24px;
    width:200px;
}

.board_info textarea{
    width:80%;
    height:50px;
}
.board_info button{
    border:1px solid #a6a6a6;
    background-color: transparent;
}
.board_setting .admin_list, .board_setting .viewer_list{
    border:2px dashed #ddd;
    padding:10px 20px;
    margin-top:10px;
    /*width:calc(100% - 100px);*/
}
.board_setting .admin, .board_setting .viewer{

display:flex;
justify-content: space-between;
background-color: #eee;
padding:5px 10px;
margin-bottom:5px;
}


@media (max-width: 1300px) {

    .pw .btns{
        flex-direction: column;
        align-items: start;
        
    }
    .pw .btns .left input{
        margin:0 0 0 0;
    }
    .pw .btns .left button:first-of-type{
        margin-left:10px;
    }

    .pw .btns .right, .pw .btns .break{
        margin-top:10px;
    }
    .board_setting{
        width: 100%;
        margin:30px 0 0 0;
        box-sizing: border-box;

    }
    .board_setting .admin_list, .board_setting .viewer_list {

        width:200px;
    }
}

@media (max-width: 600px) {
    .board_setting .admin_list, .board_setting .viewer_list {

        width:auto;
    }
}