.sidenav .list{
    font-size: 13px;
    padding:0 30px;
    margin-top:20px;
}
.sidenav.email a:hover{
  background-color: transparent;
}
.sidenav.email .list{
  font-size: 13px;
  padding:0;
  margin-top:0px !important;
}

.sidenav.email .list li{

    padding:5px 30px;
}

.sidenav.email .list .active li{
background-color: aliceblue;
font-weight: bold;
color:#4c6ddc;
}
.separated {
    margin-top: 10px !important;
    display:block;
    padding-top: 10px;
    border-top: 1px solid #eaeaea !important;
}

.sidenav {
    height: calc(100vh - 60px);
    width: 230px;
    z-index: 1;
    background-color: #fefefe;
    top: 60px;
    left: 0;
    overflow-x: hidden;
    border-right: 1px solid #c7c7c7;
  }
  
  .sidenav li {
    
    text-decoration: none;
    
    padding:5px 0;
    
    display: flex;
    align-items: center;
  }
  .sidenav li svg{
    font-size: 18px;
    color:#a9a9a9; 
    margin-right: 5px;
  }
  .write_btn{
    width:100%;
    margin:15px auto;
    text-align: center;
    display: flex;
    background-color: #243863;
    color:white;
    border-radius: 8px;

  }
  .write_btn a{
    padding:10px 20px;
      
    width:50%;
    color:white;
    
    
    
    
  }
  .write_btn a:first-of-type{
    border-right: 1px solid #ddd;
  }
  
.unseen_info {
    padding: 9px 18px 8px;
    box-sizing: border-box;
    display: table;
    width: 100%;
    table-layout: fixed;
    background-color: #fff;
    border-bottom:1px solid #e3e2e2;
}
.unseen_info .item {
    text-align: center;
    position: relative;
    line-height: 24px;
    display: table-cell;
}

.unseen_info .count {
      
    font-weight: 500;
    margin-left:5px;
    vertical-align: top;
    line-height: 16px;
    color: #0073e6;
 

}


.lnb_filtered_mailbox .text {
    font-size: 13px;
}
.lnb_filtered_mailbox .text {
    color: #929294;
    vertical-align: top;
}


.lnb_filtered_mailbox .item[class*='svg_']:before {
    margin-top: 10px;
}
.lnb_filtered_mailbox .item.svg_bookmark:before {
    position: absolute;
    width: 20px;
    height: 19px;
    background-image: url(star.svg);
    background-repeat: no-repeat;
    content: '';
}
.lnb_filtered_mailbox .item[class*='svg_']:before {
    top: 5px;
    left: 0;
    right: 0;
    margin: 0 auto;
}
.side-mobile-menu{
  display:none;
}
@media (max-width: 1300px) {
.sidenav{
  display:none;
}
.side-menu{
  display:none;

}
.side-mobile-menu{
  display:block;
  position: absolute;
  top:60px;
  background-color: #ddd;
  width:100%;
  left:0;
  z-index:1010;
  height:100%;
  font-size: 14px;
}
.side-mobile-menu .board_nav{
  width:100%;
  box-sizing: border-box;
    
  position: absolute;
  
}

}
 