.mail_sign_table{
    width:100%;
    background-color: white;
    border-collapse: collapse;
}
.mail_sign_table tr{
    border-bottom: 1px solid #ddd;
}

.mail_sign_table tr th{
    padding:15px;
}
.mail_sign_table tr th button{
    border:1px solid #ddd;
    background-color: white;
    padding:5px 8px;
    margin-left: 5px;
}
.mail_sign_table tr td{
    padding:15px;
}

.mail_sign_table tr textarea{
    height:100px;
}

#sample table tr td, #sample p{
    padding:0;margin:0;
}

