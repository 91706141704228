

.shareMail .input-group {
background-color: #f7f7f7;
margin-bottom: 10px;
}

.shareMail .rec_list{
    padding:10px;
}

.shareMail .input-group .user_list {
    background-color: rgb(218,220,224);
    border-radius: 10px;
    font-size:13px;
    height:auto;
    margin:0 0 0 5px;

}

.shareMail .input-group input[type="text"]{
    height:40px; padding:0 10px;
    -moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
background-color: #f7f7f7;
}


.shareMail .input-group textarea{
    height:120px;
    border:none;
    background-color: #f7f7f7;
    
    padding:10px;
    -moz-box-sizing: border-box;
-webkit-box-sizing: border-box;
box-sizing: border-box;
}

.shareMail .buttons{
    position: relative;
    margin-top:20px;
    bottom:0;
}
.shareMail .buttons button{
    border:none;
    height:30px;
    line-height: 0;
    width:130px;
    cursor:Pointer;
}