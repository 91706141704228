.addr_table{
    width:100%;
    border-radius: 5px;
}
.addr_table th{
    padding:5px 0;
    background-color: #fff !important;
}
.addr_table td{
    padding:10px 0;
}
.addr_table td input, .addr_table td select{
    border:1px solid #a6a6a6;
    height:20px;

}