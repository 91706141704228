.mail_done{
    padding:50px;
    width:100%;
    height:300px;
    border-bottom: 1px solid #ddd;
}
.mail_done svg{
    font-size:30px;
    color:rgb(153, 231, 254);
}

.mail_done table{
    margin:30px;
    max-width:500px;
    
}
.mail_done table td{
    text-align: left;
}

.mail_done table td:first-of-type{
    width:100px;
}

.mail_done .btns .btn{
    border-radius: 7px;
    width:100px;
    text-align: center;
}

.mail_done .btns .btn:first-of-type{
    background-color: rgb(184, 238, 255);
    border: none;
}

@media (max-width: 698px) {
    .mail_done table{
        margin:30px;
        width:auto;
    }
    .mail_done{
    padding:50px 0;
    }
    .mail_done table td:first-of-type{
        width:auto;
    }
    
}