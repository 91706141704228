.content-wrap{
    width:100%;
    
    /*height:calc(100vh - 60px);*/
    height:fit-content; /*check*/
    padding-top: 60px;
}

.dashboard-wrap{
    background-color: #F1F2FB;
    height:100vh;
   
    
}

.dashboard{
    display:flex;
    padding:2% 5%;
    font-size:13px;
    box-sizing: border-box;
    overflow-x: hidden;
    justify-content: space-between;
    
}

.dashboard .item{
    display:flex;
    justify-content: space-between;
    padding:7px 10px;
border-top: 1px solid #ddd;
}
.dashboard .item a:hover{
    background-color: transparent;
    color:#2282f8;
    font-weight: bold;
    text-decoration: underline;
}
.dashboard .col1, .dashboard .col2, .dashboard .col3{
    display:flex;
    flex-direction: column;
}
.dashboard .col1{
    width:15%;
    min-width:150px;
    
}
.dashboard .col1 .profile{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px;
    background-color: #fff;
    border:1px solid #a6a6a6;

    box-sizing: border-box;

    
}
.dashboard .col1 .user_info{
    font-size:18px;
    margin:20px 0;
    display:table;

}
.dashboard .col1 .user_info span:not(:first-of-type){
      
    margin-left:5px;

}

.dashboard .col1 .user_info span:not(:first-of-type)::before{

    content:"";
    display: inline-block;
    width: 1px;
    height: 13px;
    background:#b6b6b6;
    margin-right: 5px;

}
.dashboard .col1 .new_alert span{
    background-color: #a0a7ac;
    color: #fff;
    padding:0 5px;
}
.dashboard .col1 .new_alert{
    width:80%;
    display:flex;
    justify-content: space-between;
    padding:5px 10px;
    cursor: pointer;
    font-size:13px;
    margin-bottom:10px;
    border: 1px solid #a6a6a6;
}
.dashboard .col1 .new_alert:hover{
    background-color: #80bdff;
    color:#2742d9;
}

.dashboard .email_list .item a{

width:80%;


}
.dashboard .quick a{
    display: block;
    margin: 10px;
    padding:5px;border:1px solid #ddd;border-radius:5px;
    padding:5px 10px;
}

.dashboard .quick .link span{
    display:inline-block;
    
}
.dashboard .col2{
    width:50%;
}
.dashboard .col2 .email-wrap{
    background-color: #fff;
}

.dashboard .col2 .email-wrap .email_list{
    margin:0;
}

.dashboard h3{
    width:100%;
    box-sizing: border-box;
    padding:10px;
    display:flex;
    margin: 0;
    align-items: center;
}

.dashboard h3 svg{
    color:#2742d9;
    margin-right:5px;
    
    font-size: 1.17em;
    line-height: 1.17em;
}
.dashboard .component{
    margin-top:20px;
    background-color: #fff;
    border:1px solid #ddd;
}

.dashboard .col3{
    width:30%;
}
.dashboard .work{
    background-color: #fff;
}
.dashboard .search tr{
    padding:5px;
    box-sizing: border-box;
}
.dashboard .search a{
    width:15%;
background-color: #9acbff;
height:27.33px;
text-align: center;
line-height: 27.33px;
}

.dashboard .col3 table input{
    height:24px;
    width:85%;
    border:1px solid rgb(221,221,221);
}

.dashboard .col3 table button{
    background-color: #9acbff;
    height:27.33px;
    line-height: 27.33px;
    width: 15%;
    padding:0;
    border: none;
    font-size:10px;
}

.work_list .works{
    display:flex;
    justify-content: space-between;
}
.work_list .work_item{
padding:5px 10px;
border-bottom: 1px solid #ddd;
}
.work_list .d-day{
    padding:2px 10px;
    
    background-color: #eee;
}
.work_list .duedate{

    display:flex;
    align-items: center;
}
.dashboard .title{
    width:52%;
    display:flex;
    align-items: center;
}

.work_list .status{
    padding:2px 10px;
    border-radius: 7px;
    width:10%;
    height:15px;
    line-height: 15px;
    text-align: center;
    background-color: #00bbf0;
}

.schedule_item .item{
    display:flex;
    padding:5px 10px;
    align-items: center;
    border-bottom: 1px solid #ddd;
    justify-content: space-between;
}
.schedule_item .item div{
    padding:5px 15px;
}
.schedule_item .item span{
    margin-right:10px;
}

 .absence, .birthday{
    width:48%;
}
.birthday div, .absence div{
    border-bottom:1px solid #ddd;
}
.dashboard_buttons{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
}
.dashboard_buttons button{
    padding:10px 20px;
    width:48%;
    margin-top:20px;
    border-radius: 13px;
    border:none;
    color:#fff;
    font-weight: bold;
      
    background: linear-gradient(45deg, rgb(191 199 224) 0%, rgb(145 148 205) 100%);
    cursor:pointer;
}
.dashboard_buttons button a{
    color:#fff;
}
.dashboard_buttons button a:hover{
    background-color: transparent;
}
.dashboard_buttons button:hover{

}
.dashboard .clock{
    margin-top:20px;
    padding:10px 20px;
    display:flex;
    background-color: #fff;
}

.dashboard .clock .today{
    font-size:16px;
    font-weight:bold;
    color:#224bf0;
    margin-right:20px;
}
.dashboard .col3 table tr{
    width:100%;
    display:flex;
    align-items: center;
}


.board_list .item{
    display:flex;
    align-items: center;
}
.board_list .title .notice{
    background-color: #a7a7b2;
    padding:3px 5px;
    width:auto;
    font-size:11px;
    border-radius: 5px;
    color:#fff;
    white-space: nowrap;
}

.board_list .notice.new{
    background-color: #7f7cdc;
}
.board_list .title a{
    width:100%;
}
.board_list .subject{
    margin-left: 5px;
width:100%;
}

.board_list .date{
    width:20%;
    text-align: right;
}

h3 .unread{
    /*background-color: #eeebeb;border:1px solid #000;*/
    background: #da1b1b;
    color: white;
    
    padding:2px 5px;
    border-radius: 3px;
    margin-left:5px;
}

.profile2{
    width: 100%;
    display: flex;
    flex-direction: column;
}
@media (max-width: 2000px) {
    .dashboard {
      padding:2%;
      font-size:11px;
    }
}

@media (max-width: 1700px) {
    .dashboard {
      padding:20px;
      
    }
    .board_list .subject {
        width: 80%;
    }

    .dashboard .col1 .profile{
       
        padding:50px 10px;
    
        
    }
    .dashboard .col1 .user_info{
  
        font-size:15px;
        margin:5px;
        display:table;
    }

    .dashboard .col1 .custom-file-upload img{
        width:100px;
        height: 100px;
    }
        
}

@media (max-width: 1400px) {

    /*.dashboard .col1 .profile{
       
        padding:10px;
    
        
    }
    .dashboard .col1 .user_info{
        color:#fff;
        font-size:15px;
        margin:5px;
        display:table;
    }

    .dashboard .col1 .custom-file-upload img{
        width:100px;
        height: 100px;
    }
    */

}

@media (max-width: 1200px) {
    .dashboard-wrap{
        height:100%;
    }
    .dashboard{
        flex-direction: column;
        align-items: start;
    }
    .absence{
        flex-direction: column;
    }
    .birthday{
        width:100%;
    }
    .dashboard .col1, .dashboard .col2, .dashboard .col3{
        width:100%;
    }
    .dashboard .col1 .profile{
        width:70%;
        
        margin:0 auto;
    }
    .dashboard .col1 .profile2{
        justify-content: center;
        margin-left:20px;
    }
    .dashboard .col1 {
        margin-bottom: 20px;
        flex-direction: row;
    }
    

}

@media (max-width: 698px) {

    .dashboard {
        font-size:10px;
    }
    .board_list .subject{
        width:67%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }


    .dashboard .col1 .user_info{
        margin:10px;
    }
    .dashboard .email_list .item a{

        width:50%;
        
        
    }

    .board_list .date{
        width:10%;
        text-align: right;
    }

    .dashboard .col1{
        flex-direction: column;
    }
    .dashboard .col1 .profile{
        width:100%;
        margin:0 auto;
    }
    .dashboard .col1 .profile2{
        margin:0
    }
    .dashboard .col1 {
        margin-bottom: 20px;
    }

}


@media (max-width: 440px) {

    .board_list .subject{
        width:64%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
    .board_list .date{
        width:15%;
    }




}