.main_contents_wrapper.departments{
    width:48%;
    margin:30px 0;
}

.admin_wrapper {
    
    display:flex;
    justify-content: space-between;
    min-width: 1110px;
}
#cmbSelected{
    margin:30px 0;
}
.three_cols{
    min-width:1400px;
}
.departments .folder{
    width: 95%;
    margin: 20px auto;
    min-height:450px;
    max-height:500px;
    overflow-y: scroll;
    border: 1px solid #ddd;
}
.departments .folder .FolderTree{
    padding:20px;
}

.main_contents_wrapper{
    box-sizing: border-box;
}
.main_contents_wrapper:first-of-type{
    width: 40%;
    padding: 20px;
}
.main_contents_wrapper:nth-of-type(2){
    width: 55%;
    padding: 20px;
}
.three_cols .main_contents_wrapper{
    width:30%;
    margin:30px 0;
    
}
.main_contents_wrapper ul{
    width: 95%;
    min-height:450px;

    max-height:700px;
    overflow-y: scroll;
    margin: 20px auto;
    border: 1px solid #ddd;
    padding:10px 0;
}
.main_contents_wrapper .list_wrap{
    padding:10px;
    display:flex;
}
.main_contents_wrapper li{
    display:flex;
    padding-left:20px;
}
.main_contents_wrapper li:hover{
    background-color: rgb(228, 255, 255);
    cursor: pointer;
}
.main_contents_wrapper li input{
    margin-right:15px;

}
.members .img-wrap{
    width:50px;
    height:50px;
    margin-right:15px;
    border-radius: 50%;

}
.members .img-wrap img{
    width:100%;
    height:100%;
    border-radius: 50%;
    object-fit: cover;
    border:1px solid #ddd;
}
.departments .btns input{
    margin-right:15px;
}



@media (max-width: 1300px) {

    .admin_wrapper{
        flex-direction: column;
        justify-content: start;
        min-width: unset;
    }
    .main_contents_wrapper ul{
        height:200px;
        min-height: unset;
    }
    .main_contents_wrapper .btns button{
        font-size:10px;
    }
    .contents_top table{
        font-size:12px;
    }
    .main_contents_wrapper:first-of-type{
        width: 100%;
        margin:0;
        box-sizing: border-box;
    }
    .main_contents_wrapper:nth-of-type(2){
        width: 100%;
        margin:30px 0 0 0;
        box-sizing: border-box;
    }
    .three_cols{
        min-width:unset;
    }

    .three_cols .main_contents_wrapper {
        width: 100%;
    }
    .departments .folder{
        min-height:unset;
        height:200px;
    }
}