.search_area {
  position: absolute;
  width:20%;
  min-width:300px;
  right :0%;
}

.search_bar_wrap {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height:40px;
  
}
.search_bar_wrap {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  background-color: rgba(107, 119, 172, 0.1);
}
.search_bar{
  display:flex;
  width:100%;
}

.search_bar .search_input {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  width: 90%;
  height: 40px;
  padding: 6px 0 6px 15px;
  border: 0;
  border-radius: 0;
  outline:none;
  background-color: transparent;
    
  font-weight: bold;
  color: #1e1e23;
  line-height: 20px;
}

.search_bar .button_svg_search {
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: center;
  display:none;
  
}

.button_detail{
width:80px;
height:100%;
border:none;
background-color: #1e1e23;
color:white;
cursor: pointer;
}


.search_dropdown{
  position: absolute;
  top: 40px;
  width:500px;
  right:2.5%;
  box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
  background-color: white;
  z-index:99;
  
}

.search_dropdown_list{
  padding: 16px 25px 16px 25px;
}
.search_dropdown_item{
  position: relative;
  display:flex;
  margin:15px 0;
}

.search_dropdown_item input{
  height:28px;
  width:100%;
  outline:None;
  border:none;
  border-bottom:1px solid #898989;
}
.search_dropdown_item .category {
width:15%;
line-height:28px;
  
}
.search_dropdown_item .input_wrap{
  display: flex;
  width:85%;
  left:80px;
  justify-content: space-between;
}

#search_detail_3{
width:55%;
}

.react-datepicker-wrapper, .react-datepicker__input-container, .react-datepicker-wrapper input[type=text]{
  height:28px !important;
  padding:0 !important;
  
}
.search_dropdown .date_picker{
width:48%;
}
.search_dropdown_footer{
  display:flex;
  justify-content: right;
}

.search_dropdown .button_search{
padding:5px 10px;
width:70px;
margin:10px auto 0px auto;
background-color: white;
border:1px solid #ddd;
cursor:pointer;

}


@media (max-width: 1300px) {

.search_area {
  position: relative;
  width:100%;
  right:0%;
}

.search_dropdown{
  top:85px;
  width:330px;
}
.search_dropdown_item .category{
  width:25%;
}
.search_dropdown_item .input_wrap{
  width:75%;
}

}