body {
  margin: 0;
  font-family: 'SpoqaHanSansNeo-Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}
html, body, #root {
  width: 100%;
  height: 100%;
  font-size:13px;
  margin:0;
  padding:0;
  -webkit-overflow-scrolling: touch; 

}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.admin main{
  width:calc(100% - 250px);
}
@media (max-width: 768px) {
  .admin main{
    width:100%;
  }

}
@media (max-width: 1300px) {
  html, body, #root{
    
    /*
    font-size:10px;
    overflow-x: hidden;*/
  }

}

@media (max-width: 600px) {
  html, body, #root{
    font-size:10px;
    /*
    font-size:10px;
    overflow-x: hidden;*/
  }

}