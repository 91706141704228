nav{
    height:60px;
    background-color: #676d8b;
    position:fixed;
    top:0;
    width:100%;
    z-index:9999;
    display:flex;
    justify-content: space-between;
    
}

.nav-elements{
    width:calc(100% - 200px);
    display:flex;
    justify-content: center;
}

.nav-elements ul {
    display: flex;
    flex-direction: row;
  }

.menu-items{
     padding:0px 30px;
}
.menu-items a{
    color:#fff;
    line-height: 28px;
   
}

nav .setting{
    
    background-color: transparent;
    border:none;
    color:#fff;
    /*width:200px;*/
    right:20px;
    justify-self: flex-end;
    display: flex;
    position: relative;
    align-items: center;
}
nav .setting a, nav .setting svg{
    right:20px;
    margin-left:10px;
    cursor:pointer;
    display:flex;
    font-size:30px;
}

nav .setting a:hover{
  background-color: transparent;
}
nav .setting .toAdmin{
  width:85px;
  font-size:12px;
}

nav .setting .toAdmin svg{
  width:20px;
  margin-right:5px;
}
nav .setting .toAdmin:hover{
  color:aqua;
}
.not_dropdown{
    position: absolute;
    top:40px;
    width:400px;
    color:black;
    
    
    right:-5px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
    background-color: white;
    z-index:9999;
    
}

.not_dropdown:before {
    content: "";
    display: block;
    position: absolute;
    right: 1rem;
    top: -10px;
    border-bottom: 10px solid #f7f7f7;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}
.not_dropdown ul{
    margin:0;
    max-height:500px;
    overflow-y: auto;
  
}
.not_dropdown li{
    width:100%;
    padding:10px;
    box-sizing: border-box;
    
    background-color: white;
    border-bottom:1px solid #ddd;
    cursor: pointer;
}
.not_dropdown li:hover{
    background-color: #f7f7f7;
    
}
.not_dropdown li a{
    width:100%;
    font-size:12px;
    margin-left:0;
}
.not_dropdown li a:hover{
    background-color: transparent;
}
.not_dropdown .dropdown_header{
    padding-left:10px;
    border-bottom:2px solid #ddd;
}
.not_dropdown .dropdown_footer button{
    width:100%;
    background-color: #FFF;
    border:none;
    cursor: pointer;
    padding:10px 0;
}
.notification {
    width:45px;
    position: relative;
    
  }
.notification .badge {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size:12px;
    padding: 2px 5px;
    border-radius: 50%;
    background-color: rgb(218, 60, 60);
    color: white;
  }
  .menu-icon {
    display: none;
  }

  .toAdmin{
    font-size:12px; display:flex; align-items:center;
color:white; font-weight:bold; border:1px solid #fff;
  }
  .four_square{
    display:none;
  }

  .main_menu{

    width:100%;
    height:100vh;
    border-top:1px solid #fff;
    position:absolute;
    top:60px;left:0;
    background-color: #676d8b;
  }
.main_menu_wrap{
  display: grid;
  gap: 1px;
  grid-template-columns: repeat(3, 1fr);
}

.main_menu_wrap .col{
  padding:20px;
}
.main_menu_wrap .col svg{
  font-size: 45px;
}
.main_menu_wrap .col .card{
  display:flex;
  color:#fff;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.logout{
  border:none;
  background-color: transparent;
  color:white;
  cursor: pointer;
}
nav .setting .toAdmin{
  padding:0 10px;
  text-align: center;

}
.card_icon {
  position: relative;
}
.card_icon .unread{
  font-size: 14px;
  background: #da1b1b;
  color: white;
  padding: 2px 5px;
  position: absolute;
  border-radius: 5px;
  margin-left:-10px;

}
@media (min-width: 1300px) {
  .main_menu{
    display: none;
  }
}

@media (max-width: 1450px) {
.menu-items{
  padding:0px 30px;
}
}
  @media (max-width: 1300px) {

    

    nav{
      z-index:100;
    }
    nav .setting .userImg{
      display:none;
    }
    nav .setting .logout{
      display:none;
    }
    .nav-elements .menus{
      display:none;
    }
    nav .setting .toAdmin{
      display:none !important;
    }

    .logout{
      display:none;
    }

    .four_square{
      display:block;
    }

    nav{
      width:100%;
      display: flex;
      justify-content: space-between;
    }
    .menu-icon {
      /*width:50px;*/
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-right:10px;
    }
    .menu-icon svg{
        font-size: 28px;
        color:white;
    }
  
  
    .nav-elements.active {
      width: 100%;
    }
  
    .nav-elements ul {
      display: grid;
/*      flex-direction: column;*/
    }
  
    .nav-elements ul li {
      margin-right: unset;
      margin-top: 22px;
    }
    nav .setting{
      right:0;
      width:auto;
      
    }

    .not_dropdown{
      position: absolute;
      top:40px;
      width:270px;
      color:black;
      
      
      right:-5px;
      box-shadow: 0 2px 4px rgb(0 0 0 / 20%);
      background-color: white;
      z-index:9999;
      
    }

  }


  @media (max-width: 600px) {
    .nav-elements .setting .notification img{
      margin-right:30px;
    }
  }