ul{
    padding-left:0;
}
li {
    list-style: none;
}
.write_table{
    width:100%;
    margin: 10px auto;
    border-collapse: collapse;
    table-layout:fixed; 
}
.write_table tr{
    height:50px;
    padding:0;
    margin:0;
    display:flex;
    width:100%;
    align-items: end;
}

.table_title2{
    width:130px;
    display:flex;
    align-items: center;
    padding-bottom:5px;
    flex-shrink: 0;
}

.table_title2 svg{
    margin-right: 5px;
    color:#999;
}

.table_list2_left{
    border-bottom: 1px solid #ddd;
}

.rec_list{
    display: flex;
    margin:0;
    align-items: center;
}

.rec_list li{
    margin-left:10px;
}

.table_list2_left{
    padding:5px;
    display:flex;
    width:100%;
}

.input-group input{
    border:none;
    width: 100%;
    padding:5px;
    box-sizing: border-box;
    outline: none;
}

.upload_file_list{
    font-size:12px;
    border:none;
}
.user_list, .cc_list, .bcc_list, .addr_list{
    display:flex;
    background-color: rgb(248, 248, 248);
    padding:5px 2px;
    height:12px;
    width:auto;
    position:relative;
    margin-left:5px;
}
.user_list li{
    list-style: none;
    width: max-content;
   
}

.user_list span{
    position: relative;
}
.del_btn{
margin-left:20px;
}

.unvalid{
    border:1px solid red;
}



/* toastui-editor-defaultUI */

.toastui-editor-defaultUI {
    position: relative;
    border: none;
}
.luckysheet-sheet-selection-calInfo {
    display: flex;
    font-size: 12px;
    align-content: center;
    padding: 0 0 0 44px;
    height: 22px;
    align-self: flex-end;
}
.large_file_btn{
    background-color: #fff;
    border:1px solid #bfbfc2;
    padding:5px 10px;
    margin:5px 5px 1px 0;
    
    border-radius: 5px;
}
.large_file_btn:hover{
    border:1px solid #000;
}
.file_item{
    display:flex;
    padding:0 10px;
    box-sizing: border-box;
    width:100%;
    
}
.upload_file_list{
    width:100%;
}
.file_item .remove{
    padding:0px;
    background-color: white;
    border:none;
    margin-left:10px;
    cursor:pointer;
    
}

.mail_button_wrap{
    width:95%;
    margin:50px auto 0 auto;
}

.mail_button_wrap button{
    background-color: #fff;
    border:1px solid #ddd;
    padding:5px 20px;
    margin-right:10px;
    cursor:pointer;
}

.mail_button_wrap button:hover{
    border:1px solid #000;
}


/*******************************     BOARD       ***********************************/

.board_name{
    width:auto;
    text-align:center;
    
}

.board_name span{
    padding:5px 15px;
    background-color: #def9fc;
}

.selectNotice label{
    margin-right:20px;
}


/***************** Doc Write *******************/
@media (max-width: 1300px) {
    .selectNotice label{
        margin-right:5px;
    }
    .table_title2{
        width:90px;
    }

    .table_list2_left .input-group .list{
        flex-direction: column;
        margin-bottom:0;
    }

}