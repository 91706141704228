.sign textarea{
    height:90px;
    width:85%;
    padding:10px;
}

.sign ul{
    display:flex;
    width:90%;
    margin:0 auto;
    justify-content: center;
}

.sign ul li{
    margin-right:20px;
}


@media (max-width: 1300px) {

    .sign ul li{
        margin-right:5px;
    }

}