.login .headerTxt{
    margin-top:0;
    margin-bottom:50px;
}


.login {
    
    width:60%;
    margin:auto 20%;
    text-align: center;
    font-size: 20px;
    display:block;


}
.login .login1{
    width:40%;display:flex;
    align-items:center;
}

.login .login2{
    width:50%;display:flex;
    align-items:center;
    flex-shrink: 0;
}
.login p{
    margin-top:50px;
}

.loginForm{
    width:80%;
    margin: 0 auto;
}

.loginForm label{
    width:60%;
}

.login input {
    text-align: left;
    width:100%;
    max-width:500px;
    font-size:15px;
    margin-top:10px;
    padding:10px 30px;
    border: none;
    box-sizing: border-box;
    border:1px solid #ddd;
    background: #fafafa;
}

.login input::placeholder {
    color: black;
    font-size:15px;
}

.login .email {
    padding-top: 19px;
}

.login img {
    width: 20px;
    height: 20px;
}

.login .password {
    padding-top: 22px;
}

.div_button{
    display: flex;
    justify-content: center;
}
.login .btn-st2 {
    width: 120px;
    padding:10px 10px;
    font-size: 20px;
    background: #72a2f5;
    color:white;
    cursor:pointer;
   
    margin-top:50px;
    
}

.login_wrap .login1 img{
    width:100%; object-fit:contain; 
    object-position:center center;
}

.login1, .login2{
    width:100%;
    display:flex;
    align-items: center;
}
@media (max-width: 2200px) { 
   
}

@media (max-width: 1300px) { 
    .login_wrap{
        justify-content: center;
        flex-direction: column;
    }
    .login_wrap .login1, .login_wrap .login2{
        width:100%;
    }
    .login_wrap .login1{
        text-align: center;
        justify-content: center;
    }
    .login_wrap .login1 img{
        width:400px; object-fit:contain; 
        object-position:center center;
    }
    .login {
    
        width:80%;
        margin:auto 10%;
        text-align: center;
        font-size: 20px;
        display:block;
    
    
    }
    .login input{
       width:100%;
       margin:0;
    }
    .loginForm{
        width:100%;
        margin: 0 auto;
    }

    .login button {
        width:30%;
        margin:0 auto;
        height: 70px;
        font-size: 20px;
        font-weight: bold;
        background: white;
        
    }
   
    
}