.member_table {
    border: 1px #ddd solid;
    font-size: .9em;
    
    width: 100%;
    border-collapse: collapse;
    
    overflow: scroll;
  }
  .member_table td, .member_table tr{
    border: 1px #ddd solid;
  }
  .member_table th {
    text-align: left;
  }
    
  .member_table thead tr td{
    font-weight: bold;
    color: #fff;
    background: #7f86929a;
  }
    
  .member_table td,.member_table th {
    padding: 0.5em .5em;
    vertical-align: middle;
  }
    
  .member_table td {
    border-bottom: 1px solid rgba(0,0,0,.1);
    background: #fff;
    height:20px;
  }
  .member_table td:first-of-type {
    width:10px;
  }
  
  .member_table a {
    color: #73685d;
  }
    

  .selected_member{
    background-color: #c7eaff;
  }
   @media all and (max-width: 768px) {
      
    .member_table table, .member_table thead, .member_table tbody, .member_table th, .member_table td, .member_table tr {
      /*display: block;*/
    }
    
    .member_table th {
      text-align: right;
    }
    
    .member_table {
      position: relative; 
      padding-bottom: 0;
      border: none;
      box-shadow: 0 0 10px rgba(0,0,0,.2);
    }
    
    .member_table thead {
      /*float: left;*/
      white-space: nowrap;
    }
}