.setting_wrap{
    margin-left:300px;margin-top:80px;position: relative;
    display:flex;    flex-direction: column;
    align-items: flex-start;
}

.user_info{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left:300px;
    position: relative;
}
.box_manage{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.user_info table, .setting_wrap{
    width:40%;
    min-width:1000px;
    border-collapse: collapse;
}
.user_info table td{
    padding:20px;
}
.user_info table tr{
    height: 60px;
    width:100%;
    border-bottom:1px solid #ddd;
    vertical-align: middle;
}

.user_info table tr td span{
    font-size: 13px;
    color:#7f7f7f;
}

.user_info table tr thead{
    width:80%;
    height:100%;
    
}
.user_info .bod{
    margin-top:10px;
}
.user_info .bod select{
    width:49px;
    text-align: center;

}
.user_info .bod input{
    width:45px; padding:0;
    text-align: center;
}
.user_info .bod input:last-of-type{
    width:45px;
}

.user_info .bod input:last-of-type{
    width:45px;
}
.user_info .bod span{
    margin:0 5px;
}

.custom-file.upload button{
        background-color: white;
        cursor: pointer;
        border: 1px solid #bfbfc2;
        padding: 5px 20px;
    
}

.setting_wrap .addUser{
    position: relative;
    min-width:1200px;
    width:70%;
}
.setting_wrap .user_detail {
    padding:0;
    width:100%;
}
@media (max-width:1300px) {

    .setting_wrap .addUser{
        min-width:unset;
        width:100%;
    }

    .setting_wrap{
        margin-top:80px;
    }
    .user_info table{
        margin-top:20px;
    }
    .user_info table, .setting_wrap {
        width:100%;
        min-width:unset;
        
    }
    .setting_wrap #title{
        margin:0 auto;
        text-align: center;
    }
    .user_info table input {
        width:auto;
    }
    .setting_wrap .user_detail .addUser{
        margin-top:20px;
    }

    .user_info table td{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}