.docwrite .status{
    display:flex;
    height:auto;
    margin-top:8px;
    justify-content: space-between;
    align-items: center;
}

.docwrite .status td{
    width:22%; background-color: #e3f6ff;
    padding:20px 0;
    border-radius: 10px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.docwrite .status td span{
    color:#4c5676;
    font-weight:bold;
    text-align: center;
    
}
.docwrite .status td svg{
    cursor:pointer;
    margin-left:5px;
    color:#499ff4;
}
.table_list2_left .time{
    display:flex;
}

.ot .input-group svg{
    margin-left:-5px;
    font-size:18px;
    color:#666;
}
.ot .input-group{
    display:flex;
    align-items: center;
}

.ot .input-group input{
    border: 1px solid #666;
    padding: 5px;
    height:20px;
}
.ot .input-group span{
    white-space: nowrap;
}

.ot .input-group button{
    height:35px;
}

.ot .input-group select{
    border: 1px solid #666;
}

.leave_view{
    border-collapse: collapse;
}

.leave_view tr{
    height:auto;
    margin-bottom:10px;
}

.leave_view td{
    height:20px;
    padding:10px;
    border: 1px solid #a3a3a3;
}

.leave_view .table_title2{
    width:90px;
    background-color: #eee;
    
}

.leave_table tr:first-of-type td:nth-of-type(4){
    width: 50%;

}

.leave_table tr:first-of-type td:nth-of-type(2),.leave_table tr:first-of-type td:nth-of-type(8){

    width:30%;

}
.ot{
    margin:20px auto;
    border-collapse: collapse;
    
}
.ot .table_title2{
    width:auto;
}
.ot, .ot thead, .ot tr {
    width:100%;
}

.ot th, .ot td{
    padding:10px;
}

.ot tr{
    width:100%;
}

.ot th{
    width:15%;
    border: 1px solid;
}
.ot td {
  width:50%;
  border: 1px solid;
  height:15px;
}

.ot .time, .ot .startTime, .ot .endTime{
    display:flex;
}
.ot .input-group{
    display:flex;
    justify-content: center;
}
.docwrite{
    width: 90%;
    margin: 30px auto;
    /*overflow-x: hidden;*/
}

@media print {

    .docwrite .status td span {
        font-size:12px;
    }
    .docwrite .status td{
        display:flex;
        flex-direction: column;
    }
    .docwrite .status td svg{
        display:none;
    }

}

@media (max-width:1200px) {
    .docwrite .status td span {
        font-size:12px;
    }
    .docwrite .status td{
        display:flex;
        flex-direction: column;
    }
   
    .docwrite{
        width:100%;

        margin:10px 0;
    }
    .docwrite .btns{
        width:100%; max-width:1600px; margin:0 5px;

    }

    .ot th{
        width:20%;
        border: 1px solid;
    }
    .ot td {
      width:80%;
      border: 1px solid;
    }



}



@media (max-width:1300px) {
.leave{
    height:600px;
    /*overflow: auto;*/
}

.leave_table tr:first-of-type td:nth-of-type(2), .leave_table tr:first-of-type td:nth-of-type(8),.leave_table tr:first-of-type td:nth-of-type(4) {
    width: 100%;
}
}