.draft_list{
    height:486px;
    text-align: left;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch; 

}

.draft_list li{
    border-bottom: 1px solid #ddd;
    height:80px;
    display:flex;
    line-height: 25px;
    flex-direction: column;
    justify-content: center;
    padding:0px 20px;
    cursor: pointer;
}
.draft_list li:hover{
    background-color: #f7f7f7;
}
.draft_list li:first-of-type{
    border-top: 1px solid #ddd;
}
.draft_list li span:nth-of-type(2){
    color:#8f8f8f;
    font-size: 13px;
}
