.setting-input{
    border:1px solid #ddd;
    min-height:30px;
    width:100%;
    display: flex;
}
.sort-setting{
    max-width:1000px;
      
    border:1px solid #ddd;
    
}


.sort-setting table td{
   width:100%;
}
.sort-setting table tr{
    display: flex;
    height:auto;
    align-items: center;
    border-bottom: 1px solid #ddd;
    padding:20px;
    
}
.sort-setting table tr thead{
    width:25%;
    min-width:120px;
    text-align: left;
}
.sort-setting table td{
    display: flex;
    align-items: center;
    padding:0 10px;
}
.sort-setting table tr td input{
    width:100%;
    outline:none;
    border:none;
}

.sort-setting .action{
    width:80%;
    margin:0 auto;
    justify-content: center;
    display: flex;
}
.sort-setting .move{
    border-right: 1px solid #ddd;
}
.sort-setting .move, .sort-setting .create{
    background-color: #f8f8f8;
    margin-top:20px;
    padding:20px;
}

.sort-setting input[type="checkbox" i] {
    margin-bottom:15px;
}
.sort-setting button{
    cursor: pointer;
}
.sort-setting button:hover{

    background-color: white;
    border: 1px solid #000;

}
/**SPAM***/

.spam_list_wrap{
    border:1px solid #ddd;
    margin-top:20px;
    width:100%;
    display: flex;
}
.spam_list_wrap ul{
    display: flex;
    flex-wrap: wrap;
}
.spam_list .cc_list{
    margin-top:10px;
    margin-left:20px;
}



.add-btn{
    background-color: #ddd;
    width:10%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor:pointer;
}

.date_picker{
    width:100%;
}

.rules{
    width:80%;
    max-width:900px;
    border:1px solid #ddd;
    margin-bottom: 30px;
    border-collapse: collapse;
}



.rules thead{
    border-bottom: 1px solid #ddd;
    padding:10px 0;
}
.rules thead th{
    padding:10px 0;
}
.rules tbody td{
    border-bottom: 1px solid #ddd;
    padding:15px 0;
}

.rules .rulename{
    width:15%;
}


.spam_list_wrap .list{
    display: flex;
    justify-content: space-between;
    background-color: #f7f7f7;
    padding: 5px 10px;
    margin-left:10px;
}
.setting-input{
    overflow:hidden;
		height:auto;
}
.sort-setting .rec_list{
    width:100%;
    overflow:hidden;
		height:auto;
        flex-wrap: wrap;
}

.sort-setting table tr td input{
    height:30px;
}
.absent table tr td input{
    border:1px solid #ddd;
}

.absent table tr td input[type="text"]{
    
    width:250px;
}

.absent svg{
    margin-left:5px;
    font-size:16px;
    color:#ddd;
}

.absent .input-group img{
    width:50px;
}



.mailbox .table_list div{
    border:1px solid #ddd;
    cursor: pointer;
    padding:5px;
}

.mailbox .table_list div:hover{
    background-color: rgb(104, 205, 255);
    color:white;
    padding:5px;
}

.user_setting_wrap{
    display:flex;
}

.external_mail .radio{
    width: auto !important;
}
.external_mail .button_radio_wrap{
    display: flex;
    align-items: center;
}
.external_mail .button_radio{
    display: flex;
}
.external_mail input{
    width:auto !important;
    border:1px solid #ddd !important;
}
@media (max-width:1300px) {
    .absent table tr td input[type="text"]{
        width:150px;
    }
    .sort-setting{
        width:100%;
        margin:0;
    }
    .mailbox .table_list div{
        padding:5px;
    }

    .mailbox .table_list:nth-of-type(4){
        display:flex;
        flex-direction: column;
    }

    .sort-setting table td {
        padding:0;
        font-size:12px;
    }

    .sort-setting table tr {
      
        padding: 5px;
    }
    .mailbox .table_list:nth-of-type(4) div{
        width:100%;
    }
    .rules{
        width:100%;
        margin-left:0;
    }
    .sort-setting .action {
        width:100%;
    }
    .user_setting_wrap{
        flex-direction: column;
    }
    .setting_wrap{
        margin-left:0 !important;
    }

    .action .move, .action .create{
        width:40%;
    }
   
}
@media (max-width:600px) {


.sort-setting table tr thead {
    min-width: unset;
}

}