.stats .btns .row .box{
    padding:10px;
    background-color: #eee;
    margin-right:10px;
}
.stats .btns .left select{
    padding:10px 15px;
    border:1px solid #ddd;
    font-size:15px;
    
}