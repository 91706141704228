.schedule input{
    /*border: 1px solid #ddd;*/
}

.schedule .input-group .date{
    display:flex;
    flex-shrink: 0;
    margin:5px 0;
    align-items: center;
}

.schedule tr{
    align-items: center;
}

.schedule input[type="checkbox"]{
    height:auto;width:auto;
}
.schedule .input-group span:first-of-type{

    flex-shrink: 0;
}

.schedule .input-group label{
    width:35px;
}
.schedule .repeat tr{
    height:auto;
    border:1px solid #ddd;
    
}
.schedule .repeat td{
    display: flex;
    padding:0 10px;
}
.schedule .repeat .table_title2{
    background-color: #f7f7f7;
    padding:5px;
    height:100%;
}


.schedule .repeat label{
    margin-right:20px;
}
.schedule .table_list2_left .radio{
    flex-wrap: wrap;
}