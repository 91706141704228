.admin_hr tr td{
    padding:5px;
}
.admin_hr_wrap{
    height:100vh;
}


@media screen and (max-width: 1300px) {
    .responsive {
      border: 0;
    }
  
    .responsive caption {
      font-size: 1.3em;
    }
    
    .responsive thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    .responsive tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    .responsive td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
      box-sizing: border-box;
      width:100%;
      min-height:24px;
    }
    
    .responsive td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .responsive td:last-child {
      border-bottom: 0;
    }
    .save {
        margin-top:20px;
        padding:5px 10px;
    }
  
 


  }
  
  