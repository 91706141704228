.all_search, .board_wrapper{
    width:100%;
}
.all_search table{
    width:100%;
    border-collapse: collapse;
}

.all_search table tr{
    border-bottom:1px solid #ddd;
    
}

.all_search table tr td{
    padding:10px;
}

.all_search table .info{
    margin-top:5px;
    color:#9f9f9f;
}
.all_search table a{
    cursor: pointer;
    width:100%;
    color:rgb(18, 107, 240);
    font-weight: bold;
    
}
.all_search table a:hover{
    text-decoration: underline;
    background-color: transparent;
}
.all_search table tr:hover{
    background-color: #f7f7f7;
}

.all_search .title{
    display:flex;
    justify-content: space-between;
    align-items: center;
    border-bottom:2px solid #2a5ebd;
    color:#2a5ebd;
    padding:0 10px 0 0;
    margin:10px 0 0 0;
}
.all_search .title div{
    display:flex;
    align-items: center;
}
.all_search .title span{
    margin-left:10px;
}
.all_search .title button{
    border:1px solid #ddd;
    background-color: white;
    padding:5px 8px;
    color:#2a5ebd;
    cursor: pointer;
}

.all_search .box{
    width:100%;
    padding:50px 0;
    background-color: #f7f7f7;
    display:flex;
    justify-content: center;
    align-items: center;
}

.all_search .box input{
box-sizing: border-box;
height:35px;
width:550px;
border:3px solid #2a5ebd;
padding:0 10px;

}
.all_search .box h3{
    color: #2a5ebd;
    margin-right:10px;
}
.all_search .box button{
    cursor: pointer;
    margin-left:10px;
    width:100px;
    line-height: 0;
    background-color: #2a5ebd;
    color:white;
    border:none;
    height:35px;
}


@media (max-width: 1300px) {
.all_search .box input{
width:180px;
}
}