@page {
    size: A4;
    margin: 20mm;
}
select{
    background-color: #fff;
}
button{
    background-color: white;
    border: 1px solid #333;
    padding:5px 10px;
    cursor: pointer;
}
img{
    max-width: 100%;
}
.wrapper{
    width:100%;
    height:100%;
}
.outer_wrapper {
    /*height:100vh;*/
    height:100%;
    /*min-height:100vh; */  /*check*/
    display:flex;
    justify-content: space-between;
    /*margin-bottom:80px;*/
    -webkit-overflow-scrolling: touch; 
}
textarea{
    outline:none;
}
::-webkit-resizer {
    display: none;
}
.react-pdf__Page__textContent, .react-pdf__Page__annotations {
    display: none;
}

.mail_header{
    text-align: center;
    width:100%;
    display: flex;
    justify-content: center;
}
.mail_header h3{
    font-weight: normal;
}
.button_wrap{
    width:100%;
    display: flex;
    justify-content: center;
}
.submit{
    border:none;
    background-color: #0073e6;
    color:white;
    padding:10px 25px;
    border-radius: 20px;
    margin:10px auto;
    cursor:pointer;
}
.submit:hover{
    background-color: #89c4ff;
    color:#000;
}

.mobile{
    display:none;
}
.contents_wrap{
    margin: 0 30px;
    margin-top:80px;
    margin-left:200px;
    min-width:800px;
}
  
.contents{
    width:80%;
    min-width:1000px;
    max-width:1400px;
}
.btns{
    display:flex; justify-content: center;
    /*width:95%; max-width:1600px; margin:0 50px;justify-content:space-between; */
}
.btns input{
    border:1px solid #ddd;
    margin:0 10px;
}
.side-menu{
    min-height: calc(100vh - 60px); /*check*/
}
.input-group input[type='date'] {
    width:115px;
    flex-shrink: 0;
}

table th{
    white-space: nowrap;
}
table .check{
    text-align: left;
}
.headers-1 td div{
    white-space: nowrap;
    margin:5px;
}

.line {
    text-align: center;
}
.menu_icon{
    display:none;
}
.menu_icon svg{
    cursor: pointer;
    width:25px;
    float:right;
    margin-top:30px;
    margin-right:30px;
    height:25px;
    

}

.sort_table{
    cursor: pointer;
}


.pointer{
    cursor: pointer;
}

    .toastui-editor-defaultUI{
        border-top:3px solid #6fa5d1;
    }
    
    @media print {  
    /*.custom_form{
        height:100vh !important;
        overflow: hidden !important;
        padding: 20px;
        box-sizing: border-box; 
    }*/
    .leave {
        height: 100%;
    }
    /*.form_view{
        padding:20px;
        height:auto !important;
        box-sizing: border-box;
    }*/
    .form_view{
        padding:0px;
        height:auto !important;
        box-sizing: border-box;
    }
    .excel_comment {
        top:900px;
        position: relative;
    }

    .commentBtn, .comment_write_area{
        display: none;
    }

    .doc_header h3{
        width:100% !important;
        text-align: center !important;
    }

    .custom_form{
        height:100% !important;
        overflow: hidden !important;
        width: 100% !important;
    }
    .outer_wrapper, .form_view, .main_content{
        height: 100% !important;
        min-height: 100%  !important;
        margin-bottom:0 !important;
    }

    /*.custom_form .outer_wrapper{
        margin-bottom:0 !important;
        height:auto !important;
    }*/
    .custom_form .order_write{
        min-height: unset !important;
    }
    .custom_form div:nth-of-type(1){
        width: 100% !important;
    }
    .custom_form .order_write{
        padding: 0 !important;
        min-height:unset !important;
    }
    .form_write tr, .form_write td, .form_write th, .form_write div {
        min-height:unset !important;
        height:20px !important;
        padding:0 !important;
    }
    .leave_view .leave_info td{
        height:25px !important;
        padding:5px !important;
    }
    @page {
      size: 210mm 297mm;
      margin: 10px;
    }
    .line{
        padding:0 20px;
        box-sizing: border-box;
    }

    .line table td{
        padding: 0 10px !important;
        font-size:10px !important;
    }
    .line table img{
        padding:5px 0 !important;
    }

    h3{
        margin-left:20px;
    }
    .form_wrap{
        padding:20px;
        box-sizing: border-box;
        
    }
    .luckysheet-scrollbar-x{
        display:none;
    }
}
.main_content .btns{
    display: flex;
    justify-content: start;
    width: 35%;
    align-items: center;
    margin: 0px 0px 20px;
}
.mobile {
    display:none !important;
}
@media (max-width: 1300px) {
    
    .main_content .btns{
        margin: 0px 0px 0px 0px;
    }
    .main_content .btns{
        width:100%;
    }
    
    .contents{
        width:100%;
        min-width:unset;
    }
    .mobile{
        display:flex !important;
    }
}
.sidebar-icon{
    display:none;
}
@media (max-width: 1300px) {
    .menu_icon {
        display:block;
    }
    .menu_icon .active{
        color:#fff;
    }
    .sidebar-icon{
        width:60px;
        height:60px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: fixed;
        top:0;
        left:0;
        color:#fff;
        z-index: 1005;
        cursor: pointer;
    }
   
    .sidebar-icon svg{
        font-size: 28px;
        color:white;
    }

    select, input, button{
        font-size: 11px;
    }
}

@media (max-width: 600px) {
    .btn{
        font-size:11px;
    }


}