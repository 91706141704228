.expenditure {
}

.expenditure th{
    width: 50px;
}
.expenditure tr{
   
    height:28px;
}

.receipt input{
    border:none;
    width:auto;
}

.receipt tr td, .receipt tr th{
    border:1px solid #ddd;
    width:17% !important;
   
}

.expenditure td{
    width: 99% !important;
    padding:5px;
    border: 1px solid #ddd;
   
}

.expenditure td textarea{
  border:none;
}

.expenditure td textarea:focus { outline: 0; }
.receipt tr th input{
    width:40%;
}
.receipt tr td input{
    width:160px;
}

.expend_list {
    border-collapse: collapse;
    width: calc(100% - 60px);
    text-align: center;
}
.expend_list td, .expend_list th{
    border: 1px solid #000 !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox  */
input[type='number'] {
  -moz-appearance: textfield;
}

