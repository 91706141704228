.schedule_nav input[type=checkbox] {
  cursor: pointer;
    
  width: 120%;
  height: 120%;
  position:absolute;
  top:-1px;
  left:-1px;
  margin:0;
  border:none;
}

.schedule_nav .list_title{
  width:100%;
  align-items: center;
  display:flex;
  justify-content: space-between;
}
.schedule_nav .list_title span{
  display:flex;
}
.schedule_nav .list_title a svg:last-of-type{
  cursor: pointer;
  color:#bcbcbc;
}

.schedule_nav .list .menus svg{
  margin-right:5px;
  font-size:13px;
  color:#666;
  cursor:pointer;
}


