.report_w table th{
    padding:5px 0;
}

.report_w table td{
    padding:10px 0;
}

.report_w table td select{
    border: 1px solid #ddd;
    height:20px;
    width:200px;
}

.report_w table td input{
    border: 1px solid #ddd;
    height:20px;
    padding:0;
    margin-right:5px;
}
.report_w .row .box {
    cursor: default;
    padding:5px 10px;
    border:1px solid #6fa5d1;
    margin-bottom: 10px;
    display:flex;
    align-items: center;
}
.report_w .row .box input{
    border:none;
}
.report_w .row .box .icon{
    display:flex;
    align-items: center;
}
.report_w .row .box:hover svg{
    color:#6fa5d1;
}
.report_w .row .box svg:hover{
    color:black;
}
.report_w{
    width: 70%;
    min-height: 350px;
    max-height: 1000px;
    position: relative;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    top: 50%;
    display: flex;
    flex-direction: column;
}

.report_w table td input{
    width:150px;
}

@media (max-width: 1300px) {
    
.report_w table td input{
    width:100px;
}
    .report_w table td{
        width:100%;
        border:none;
    }
.report_w table td select {
    width:100px;
}
}