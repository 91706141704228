.popup_buttons{
    display:flex;

}

.popup_buttons .button{
    padding:0px;
    font-size:12px;
    cursor:pointer;
    margin-left:10px;
    background-color: #eee;
    text-align: center;
    width:100px;
}
.popup_buttons .button:hover{
    background-color: #ddd;
}
.file_list{
    margin-left:10px;
    margin-right:10px;
    margin-top:20px;
    min-height:200px;
    border:1px solid #000;
    
}
.upload_wrapper{
    margin:20px;
}
.top_menu{
    display:flex;
    justify-content: space-between;
    background: #eee;
    font-size: 11px;
    border-bottom:1px solid #000;
    text-align: center;
    
}
.files{
    display:flex;
    justify-content: space-between;
    font-size: 11px;
    text-align: center;
    border-bottom:1px solid #eee;
}
.files:hover{
    background-color: #eee;
}
.top_menu .t0{
    width:30px;
    cursor:pointer;
    border-right:1px solid #000;
}
.top_menu .t1{
    width:40%;
    border-right:1px solid #000;
}

.top_menu .t2{
    width:20%;
    border-right:1px solid #000;
}
.top_menu .t3{
    width:15%;
    border-right:1px solid #000;
}
.top_menu .t4{
    width:15%;
    border-right:1px solid #000;
}
.top_menu .t5{
    width:20%;
}
.files .t0{
    cursor:pointer;
    width:30px;
}
.files .t1{
    width:40%;
    
}
.files .t2, .files .t5{
    width:20%;
    
}
.files .t3{
    width:15%;
    
}
.files .t4{
    width:15%;
    
}

#progress {
    width: 0;
    height: 100%;
}


.dropzone{
    text-align: center;
    text-transform: uppercase;
  }
  .dropzone.active{
    border-color:#fff;
  }
  .file{
    text-decoration:none;
    
    display:flex;
    width:100%;
    background-color: #ededed;
    border-radius: 10px;
    margin-top: 20px;
    padding: 10px;
    position:relative;
    overflow: hidden;
  }
  .progress_bar{
    display: block;
    width:80%;
    margin-left:10%;
    margin-block-start: 1em;
    margin-block-end: 1em;
    
    color:#fff;
  }
   .progress {
    padding:5px 0;
    background-color: rgba(71, 71, 219, 0.8);
    inset:0;
    width:0;
    text-align: center;
  }
  .done{
    background-color: rgba(71, 71, 219, 0.8);
    height:100%;
    width:100%;
    
  }
  .files{
    justify-content: flex;
    align-items: center;
  }
  .files p{
    margin:0;
  }