
.actions{
    display:flex; align-items:center; justify-content:space-between;
}
.line_table2  {
    max-height: 200px;
    overflow-y: auto;
}
.line_main span {
    width:90px;
}
.linelist{
height:auto;
}
@media (max-width: 1300px) {
    .actions{
        flex-direction: column;
    }
    .actions .btns{
        justify-content: start;
        width:100%;
    }
    .actions div:nth-of-type(2){
        margin-top: 5px;
    }

}

@media (max-width: 600px) {
    .line_table2 {
        max-height: 150px;
    }
    .line_table2 table td {
        padding: 5px 0 !important;
    }
    .line_table2 table thead{
        display:none;
    }
    .line_main span {
        width:60px;
    }
    .line_main span:nth-of-type(2) {
        width:70px;
    }
    .linelist{
        height:100%;
        overflow:auto;
    }
}