.mail-settings h2{
    margin-left:20px;
}

.settings{
    padding:10px 20px;
    width:100%;
    display:flex;
}

.settings .button{
    margin:20px 0;
    background-color: #bfeaff;
    border:none;
    padding:10px 30px;
    border-radius: 20px;

}


.mail_modal {
    font-family: arial;
  }
  
  .mail_modal {
    position: fixed;
    width: 400px;
    z-index: 1040;
    top: 30%;
    left: 30%;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  
  .backdrop {
    position: fixed;
    z-index: 1040;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000;
    opacity: 0.5;
  }
  
  .modal-title {
    font-weight: 500;
    font-size: 1.25rem;
  }
  
  .modal-header {
    border-bottom: 1px solid #e9ecef;
    display: flex;
    justify-content: space-between;
  }
  
  .modal-desc,
  .modal-header,
  .modal-footer {
    padding: 12px;
  }
  
  .close-button {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    border: none;
    background-color: white;
    opacity: 0.7;
    cursor: pointer;
  }
 
  
  .modal-footer {
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-end;
    gap: 8px;
  }
  
  .secondary-button {
    color: #fff;
    background-color: #6c757d;
  }
  .primary-button {
    color: #fff;
    background-color: #007bff;
  }
  
  .modal-example {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  textarea{
    width:100%;
    border:1px solid #ddd;
  }