.tree-node {
    border-left: 1px dashed #aaa;
    padding-left: 8px;
    position: relative;
    margin-top:10px;
  }
  
  .node-toggle {
    cursor: pointer;
    transition: color 0.3s;
  }
  
  .child-nodes {
    list-style: none;
    padding-left: 8px;
  }
  
  .child-nodes > li {
    margin: 5px 0;
  }

  .tree-item{
    display: flex;
  }

  .tree-item .unread{
    background-color: #8f8f8f;
    color: #fff;
    border-radius: 4px;
    margin-left: 5px;
    padding: 2px 5px;
    font-size: 10px;
  }