.forms {
    overflow-x: scroll;
}
.custom_form, .form_view{
    width: 100%;
    /*height: 100vh;*/
    display: flex;
    justify-content: center;
}
.form_view .wrapper{
    /*position: absolute;*/
    inset: 0px;
    margin: auto;
}
.forms td input{
    width:98%; margin:0;
    margin-left:0.5%;
    padding:0;
    border:none;
}
.order_table, .order_table td{
    background: white; padding: 0px !important; border: 0px currentColor; height: 60px; text-align: center; color: black; font-size: 25px; font-weight: bold; vertical-align: middle;
}
.order_table .table_title2{
    width:60px;
}
.order_table{
    border: 0px solid rgb(0, 0, 0); font-family: malgun gothic,dotum,arial,tahoma; margin-top: 1px; border-collapse: collapse;
}

.order_write{
    width: 210mm;
    /*min-height: 297mm;*/
    padding: 20mm;
    overflow: hidden;
}

.order_write .headers{
    width:400px !important;
}
.order_write .headers th{
    width:80px;
}
.order_write .headers-1, .order_write .headers-1 td{
    border-collapse: collapse;
    border:1px solid #000;
    text-align: center;
    width:107px;
}
.order_write .headers-1 .title{
    height:100px; width:60%;
    font-weight: bold;
    font-size:22px;
}
.order_write .headers-1 .subtitle{
    width:10%;
}
.order_write .headers-1 .approve{
    height:30px;
}
.form_list{
    margin-top: 20px;
}
.form_list td{
    border:1px solid #000;
    text-align: center;
    padding:2px 0;
}
.order_list td:nth-of-type(3){
    width:50px;
}
.order_list td:nth-of-type(4){
    width:15%;
}
.order_list td:nth-of-type(5){
    width:15%;
}
.form_list td:first-of-type{
    width:35px;
}
.order_list td:nth-of-type(7), .request_list td:nth-of-type(7), .confirm_list td:nth-of-type(9),
.endoscope_list td:nth-of-type(8){
    width:35px;
}
.form_list, .form_list th{border: 1px solid rgb(0, 0, 0); }
.form_list th{
    padding:5px 0;
    background-color: rgb(226, 226, 226);
}

.repair_list th{
    background:#fff;
}
.form_list input{
    width:120px;
}
.form_list td:nth-of-type(1) input{
    width:20px;
}

.form_list td button{
    border:1px solid gray;
    background-color: #fff;
    color:gray;
}


.header th{
    width:12%;
}

.header td{
    width:33%;
}


/**********내시경 검진장비 유지보수 견적서 *************/

.forms .supply{
    width:55%;
    border-collapse: collapse;
}
.forms .supply td{
    padding:5px;
}

.forms .endoscope_list th{
    background-color: #b4c6e7;
}

.endoscope-2{
    border-collapse: collapse;
}
.endoscope-2 th{
    background-color: #d9d9d9;
    padding:5px;
}

.endoscope-2 td{
    padding:5px;
}


.headers-1 .line table{
    border:1px solid #000;
}
.headers-1 .line table .type{
    background-color: #fff;
}
.headers-1 .line table .name{
    min-height:unset;
    min-width: unset;
}

.order_write .headers-1 .line td {
    width:80px;
} 


/********************** 대여장비 확인서 *************************/

.rent table tbody tr th{
    width:10%;
}

.rent table tbody tr td{
    width:40%;
}

/***************************고객 서비스 카드****************************/
.service .table1{
    width:200px;
    margin-left:50px;
}
.service .table1 td{
    width:60%;
    
}
.service table th{
    width:60px;
    padding:5px 0;
    font-size:11px;
    border: 1px solid #000;

    background-color: #d9e1f2;
}

.service table td{
    border: 1px solid #000;
    min-width: 90px;
    font-size:11px;
}

.service .table2 td:first-of-type{
    min-width:unset;
    width:50px;

}

.service .table2 td:nth-of-type(3){
    min-width:unset;
    width:50px;

}

.service .table2 td:last-of-type{
    width:50%;
}

.service .table4 td, .service .table3 td{
    min-width: unset;
}
.service .table3 th{
    width:auto;
}
.service .table3 td:nth-of-type(8){
    text-align: center;
    width:30px;

}
.service .table4 td:first-of-type{
    width:60%;
}

.service .table4 td input{
    margin-top:2px;
}


/****************내시경 검진장비 유지보수 견적서*****************/
.endoscope .table1 input{
    border-bottom: 1px solid #ddd;
}

.inspect .line td{
    padding:10px;
    border: 1px solid #ddd;
}
.inspect .line div{
    padding:10px;
    
}

.purchase input{
    width:100%;
    padding:0;
    margin:0;
    border: none;
    box-sizing: border-box;
}

.purchase th{
    background-color: white;
}
.purchase .order_list td input{
    width:98%;
}
.purchase .order_list td{
    width:11%;
    height:15px;
}


.purchase .order_list td:nth-of-type(10){
    width:20px !important;
}

.purchase .order_list button{
    width:20px !important;
    padding:0;
}

.purchase_header th{
    width:70px;
}

.purchase_header td{
    min-width:50px;
}