.contents{
    padding:30px;
    box-sizing: border-box;
}

.contents_top{
    width:100%;
    margin:0 auto;
    background-color: white;
    border-radius: 5px;
    padding:20px 0;
}

.contents_top table{
    padding:0 20px;
}
.contents_top table tr td{
width:20%;
 
}
.contents_top table tr td:last-of-type{
    width:30%;
     
}

.main_contents_wrapper{
    width:100%;
    margin:30px auto;
    background-color: white;
    border-radius: 5px;
    padding:20px 0;
}
.btns .btn, .btns button{
    background-color: white;
    cursor:pointer;
    border:1px solid #bfbfc2;
    padding:5px 10px;
}
.btns button:hover{
    border:1px solid #000;
    
}

.main_contents_wrapper table {
    margin:20px auto 0 auto;
    border-collapse: collapse;
    width:95%;
    text-align: center;
}
.fixed_headers{
    padding-bottom: 5px;
    border-bottom:1px solid #bfbfc2;
}
.fixed_headers thead{
    border-top:1px solid #bfbfc2;
    padding:5px 0;
    border-bottom:1px solid #bfbfc2;
}
.fixed_headers th{
    padding:8px 0;
}

.main_contents_wrapper .btns{
    width:95%;
    justify-content: start;
    margin:0 auto;
    align-items: center;
}
.fixed_headers .name{
    width:15%;
}
.fixed_headers .id{
    width:15%;
}
.fixed_headers td a{
    display:block;
    padding:8px 0;
    width:100%;
}
.fixed_headers td a:hover{
    background-color: rgb(228, 255, 255);
}
.fixed_headers tr:hover{
    background-color: rgb(228, 255, 255);
    cursor: pointer;
}
.fixed_headers .d_name{
    width:20%;
}
.fixed_headers .email_address{
    width:30%;
}
.fixed_headers .check{
    width:2%;
}

.user_detail{
    background-color: white;
    padding:30px;
}

.info_summary{
    border-collapse: collapse;
    border:1px solid #ddd;
    margin-bottom:30px !important;
}

.info_summary th{
    background-color: #eee;
    font-weight: normal;
    width:80px;
    border-bottom:1px solid #b4b4b4;
    padding:5px 10px;
}


.info_summary td{
    border:1px solid #ddd;
    font-weight: normal;
      
    padding:5px 10px;
    text-align: center;
}

.info_summary .del{
    width:35px;
}

.admin_leave span{
    display:inline-block;
}

.admin_leave thead tr th{
    background-color: #f7f7f7;
    padding:10px 0;
}

.admin_leave tbody tr td{
    padding:5px 0;
    height:15px;
}

.addInfo td:first-of-type{
    width:20%;
}
.admin_leave_table tr th {
    cursor: pointer;
}
.admin_leave_table .leaves span{

    width:70px;

}

.admin_leave_table .leaves span:nth-of-type(2){
    width:5px;
}


@media (max-width: 1500px) {
    .admin_leave_table .leaves span{
        text-align:left;
    }
    .admin_leave_table .leaves span:first-of-type, .admin_leave_table .leaves span:last-of-type{
        border:1px solid #ddd;
        
    }

    .admin_leave_table .leaves span input{
        border:none;
        font-size:1em;
        width: 25px;
        padding:0;
        
    }

}
@media (max-width: 1300px) {
    .profile th{
        display:none;
    }
    .profile th, .profile td, .profile tr{
        width:100%;
    }

    .profile td:last-child {
        border-bottom: 1px solid #ddd !important;
    }

    .profile tr{
        
        border-bottom: none !important;
    }
    .user_detail{
        padding:5px;
    }
    .info_summary td {
        box-sizing: border-box;
    }
    .user_detail .btns .right button, .user_detail .btns .left button{
        font-size:10px;
        padding:5px;
    }
    .addInfo td:first-of-type{
        width:100%;
    }

    .info_summary td{
        border:none;
    }

    .info_summary tr:first-of-type td:first-of-type{
        border-top:1px solid #ddd;
    }
 
    .admin_leave span{
        display:inline-block;
        width:auto;
    }


    
}
    