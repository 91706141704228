.delivery{
    border-collapse: collapse;
    width:100%;
    
    
}
.delivery .thead-dark th {
    color: #3b4863;
    border-bottom: 1px solid rgba(105, 121, 157, 0.587);
}
.delivery thead th {
    border-bottom-width: 0;
    line-height: 31px;
}
.delivery thead th, .table tbody th {
    font-weight: 500;
}
.delivery thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(72, 94, 144, 0.16);
}
.delivery tbody td {
    padding: 8px 10px;
    border-bottom: 1px solid #ddd;
    font-size:13px;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}