.addr .directory-select .directory-search{
    margin:0 0 20px 0;
}
.addr .directory-select span{
    margin-right:8px;
    padding:0 5px;
    color:#666;
}
.addr .directory-select span:hover{
    background-color: #eee;
}

.addr .search-word .selected{
    color:blue;
    font-weight: bold;
    text-decoration: underline;
}
.addr .board_nav a:hover{
    background-color: transparent;
}
.contacts thead tr th:first-of-type {
    width:60px;
    text-align: center;
}
.addr .board_top .bt_srch {
    margin-bottom: 0px;
    width: 45%;
}
.contacts thead tr th:nth-of-type(2){
    width:12%;
}
.contacts thead tr th:nth-of-type(3){
    width:15%;
}
.contacts thead tr th:nth-of-type(4){
    width:20%;
}

.contacts tbody td, .contacts thead th{
    text-align: left;
    padding:10px 0;
}

.addr .break{
    display:none;
}

.addr button{
    margin-left:10px;
}

.addrlist .board_wrap .board_top .btns{
    width:48%;
}
.addrlist .board_wrap .board_top .btns svg{
    margin-right:5px;


}
.addr .btns input{
    width:100%;
    margin:0;
}


.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
  }
  
  .controls {
    position: absolute;
    bottom: 20px;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .slider {
    padding: 22px 0px;
  }
  
  .zoom-range {
    -webkit-appearance: none;
    -moz-appearance: none;
    height: 2px;
    border-top: 5px solid #fff;
    border-bottom: 5px solid #fff;
    background: #3f51b5;
    width: 100%;
  }
  
  .zoom-range::-moz-range-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .zoom-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: 1px solid #3f51b5;
    background: #3f51b5;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  
  .controls:hover input[type='range']::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
    border-radius: 50%;
  }
  
  .controls:hover input[type='range']::-moz-range-thumb {
    box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  }

  

  
@media (max-width: 1600px) {
    .addrlist .board_wrap .board_top .btns span{
        display:none;
    }
    .addrlist .board_wrap .board_top .btns svg{
        margin-right:0;
        font-size:20px;
    }
    .search_btn {
        width:70px;
        margin-left:10px;
    }
    .addrlist .board_top .bt_input {
        width:140px;
     
    }
    .board_top .bt_input input{
        width:100%; 
        margin-left:0;
        padding-left:0;
    }
    

}
@media (max-width: 1300px) {
    .addrlist .board_wrap .board_top .btns{
        width:100%;
        display: flex;
        margin:0;
    }
  
    .addrlist .board_wrap .board_top{
        margin:0;
        flex-direction: column;
    }
    .board_top .bt_srch{
        width:100%;
        display:flex;

        justify-content: start;
    }
    .board_top .bts_slct{
        width:50px;
    }
    .board_top .bt_srch select {
        width:100%;
    }
    
    
    .board_top .bt_srch > div {
        margin-left: 0px;
    }
    .addr .directory-select .directory-search {
        margin: 10px 0;
        font-size:10px;
    }

    .addr .board_top .bt_srch {
        width:100%;
    }
    


}

@media screen and (max-width: 600px) {
    .btns, .bt_srch{
        font-size:10px;
    }
    .addr_table .table thead th:nth-of-type(2), .addr_table .table tbody td:nth-of-type(2){
        width:15%;
    }
    .addr_table .table thead th:nth-of-type(3), .addr_table .table tbody td:nth-of-type(3){
        width:60%;
    }
    .addr_table .table thead th:nth-of-type(4), .addr_table .table tbody td:nth-of-type(4),
    .addr_table .table thead th:nth-of-type(6), .addr_table .table tbody td:nth-of-type(6),
    .addr_table .table thead th:nth-of-type(7), .addr_table .table tbody td:nth-of-type(7),
    .addr_table .table thead th:nth-of-type(8), .addr_table .table tbody td:nth-of-type(8),
    .addr_table .table thead th:nth-of-type(9), .addr_table .table tbody td:nth-of-type(9) {
        display:none; 
    }
    .contacts thead tr th:first-of-type{
        width:25px;
    }
    .addr_quick {
      border: 0;
    }
  
    .addr_quick caption {
      font-size: 1.3em;
    }
    
    .addr_quick thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    
    .addr_quick tr {
      border-bottom: 3px solid #ddd;
      display: block;
      margin-bottom: .625em;
    }
    
    .addr_quick td {
      border-bottom: 1px solid #ddd;
      display: block;
      font-size: .8em;
      text-align: right;
    }
    
    .addr_quick td::before {
      /*
      * aria-label has no advantage, it won't be read inside a table
      content: attr(aria-label);
      */
      margin-bottom:10px;
      content: attr(data-label);
      float: left;
      font-weight: bold;
      text-transform: uppercase;
    }
    
    .addr_quick td:last-child {
      border-bottom: 0;
    }

    .addr .break{
        display: flex;
        flex-basis: 100%;
        height: 10px;
    }
    .addr button:nth-of-type(2){
        margin-left:5px;
    }
    .excel-btn:first-of-type{
        margin-left:0;
    }
    

}
  
  