.work_list tbody td{
    text-align: center;
}
.work_list thead th span{
    cursor:pointer;
}
.work_list thead th span:hover{
    color:#6fa5d1;
}
.work_list thead th:nth-of-type(2){
    width:25%;


}
.work_list thead th:nth-of-type(7){
    width:60px;


}
.work .row{
    display:flex;
    justify-content: flex-start;
    margin-bottom:20px;
}
.work .box svg{
    margin-left:5px;
    color:#6fa5d1;
}
.work .box {
    background: #ffffff;
    border:1px solid #6fa5d1;
    border-radius: 2px;
    margin-right:5px;
    
    cursor: pointer;
    display:flex;
    align-items: center;
 
}
.work .box a{
    
    padding: 20px 40px;
}
.work .box a:hover{
    background-color: #6fa5d1;
    color:white;
}
.work .box:hover svg{
    color:white;
}

.work .form_write .input-group{
    flex-direction: column;
    justify-content: start;
    align-items: start;
}
.work .form_write .input-group .date, .work .file_btns{
    display:flex;
    flex-direction: row;
    justify-content: start;
}
.work .form_write .input-group .date span:first-of-type{
    width:auto;
    display:flex;
    align-items: center;
    flex-shrink: 0;

}
.work .form_write .input-group, .form_write .input-group input {
    height: auto;
}
.form_write .radio input {
    height: 15px;
}
.work .form_write .input-group .date input{
    border: 1px solid #ddd;
}



table.GeneratedTable {
  width: 100%;
  background-color: #ffffff;
  border-collapse: collapse;
  border-width: 1px;
  border-color: #d1d1d1;
  border-style: solid;
  color: #000000;
  margin-bottom:20px;
}

table.GeneratedTable td, table.GeneratedTable th {
  border-width: 1px;
  border-color: #d1d1d1;
  border-style: solid;
  padding: 3px;
  font-weight: normal;

}
table.GeneratedTable td{
    padding:5px;
}
table.GeneratedTable thead {
  background-color: #ecfcff;
}

.work_list .row .box{
    padding:10px 20px;
    margin-bottom:10px;
}
.work_list .row .table{
    border:1px solid #ddd;
}
.status_filter ul{
    margin:0;
    padding:0;
    display:flex;
    margin-bottom:20px;
    border-radius: .25rem;
    box-shadow:0 4px 9px -4px rgba(0,0,0,0.35);
}
.status_filter li {
    margin: 0;
    width: 80px;
    text-align: center;
    height:30px;
    
    position: relative;
    
  }
  .status_filter li:last-of-type{
    width:110px;
  }
.status_filter label,
.status_filter input {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor:pointer;
  justify-content: center;
  display:flex;
  align-items: center;
}

.status_filter input{
    
    align-items: center;
    width:auto;
    line-height:12px;
    height:auto;
    opacity: 0.01;
    z-index: 100;

}

.status_filter label{
    padding:5px 15px;
    
    cursor:pointer;
    background-color: #e3ebf7;
}

.status_filter label:hover label{
    background: #ccd4de;
}

.status_filter input[type="radio"]:checked+label,
.Checked+label {
  background: #ccd4de;
}

/***** WORK MAIN *****/
@media (max-width: 1400px) {
    .work .board_top{
        flex-direction: column;
        align-items: start;
    }

    .work .board_top .bts_slct{
        margin-left:0;
    }
}

/***** WORK REPORT *****/

.work_report select{
    height: 33px;
    width: 120px;
    line-height: 30px;
    text-align: center;
    border: 1px solid #7987a1;
    color: #444;
    margin-right:5px;
}
.report .bts_slct:first-of-type{

    display:flex !important;
    align-items:center; 
    justify-content:end;
    margin-bottom:10px;
}
.report .row .box {
    cursor: default;
    padding: 5px 10px;
    border: 1px solid #6fa5d1;
    margin: 10px 0;
    display: flex;
    align-items: center;
}

@media (max-width: 1300px) {
    .status_filter, .status_filter ul {
        width:100%;

    }
    .status_filter li {
        margin: 0;
        width: calc(75%/5);
        text-align: center;
        height: 30px;
        position: relative;
    }
    .status_filter li:last-of-type {
        width: 25%;

    }
    .work_list thead th:nth-of-type(2) {
        width: 75%;
    }
    .work .table thead th:nth-of-type(3), .work .table tbody td:nth-of-type(3),
    .work .table thead th:nth-of-type(4), .work .table tbody td:nth-of-type(4),
    .work .table thead th:nth-of-type(5), .work .table tbody td:nth-of-type(5),
    .work .table thead th:nth-of-type(7), .work .table tbody td:nth-of-type(7),
    .work .table thead th:nth-of-type(8), .work .table tbody td:nth-of-type(8),
    .work .table thead th:nth-of-type(9), .work .table tbody td:nth-of-type(9) {
        display:none; 
    }
    .report .bts_slct:first-of-type{
        justify-content:start;
    }
    .report .bts_slct{
     
      
        justify-content:start;
    }
    .report .bts_slct span{
        display:none;
    }

}


@media (max-width: 500px) {
    .work_list tbody td:nth-of-type(2) a{
        text-align: left;
        width: 150px;
        display:block;
        text-overflow : ellipsis;
        white-space : nowrap; 
        overflow : hidden; 
    }

}